import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@utils/http';
import { baseAppUrl } from '@config/api';

export const rootSplitApi = createApi({
	reducerPath: 'rootReducer',
	baseQuery: axiosBaseQuery({ baseUrl: baseAppUrl }),
	tagTypes: [
		'TermineClient',
		'FileHandler',
		'Terminerfassung',
		'TermineAssistant',
		'TermineWahlen',
		'Notifications',
		'Honorare',
		'Account',
		'Bericht',
		'Documents',
		'Sperzeiten',
		'AdminUsers',
		'AdminUserRoles',
		'AdminNewsTitle',
		'AdminNews',
		'AdminNewsByTitle',
	],
	endpoints: () => ({}),
});
