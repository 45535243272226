import React, { useMemo } from 'react';
import { pick } from 'lodash';
import { TermineWahlenPageInterface } from '@pages/Assistant/TermineWahlen/TermineWahlen';
import { Button } from '@components';
import { useApproveTermineWahlenMutation } from '@api';

export const useTermineWahlen = (data: Array<TermineWahlenPageInterface>, page?: boolean) => {
	const [approveHandler] = useApproveTermineWahlenMutation();
	const convertData = useMemo(() => {
		if (!data) return [];
		return data.map(item => {
			const obj = {
				...pick(item, ['id', 'anmerkung', 'klient']),
				date: item.termin.split(' ')[0],
				geplanteZeit: `${item.von} - ${item.bis}`,
				subRows: [{}],
			};
			if (page)
				return {
					...obj,
					approve: (
						<Button
							className="px-6 py-2 rounded-full uppercase"
							text="aus/-wählen"
							onClick={() => approveHandler(item.id)}
						/>
					),
				};
			return obj;
		});
	}, [data]);

	return [convertData];
};
