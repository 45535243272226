import * as React from 'react';

const FileIcon = ({ ...props }) => (
	<svg fill="none" height={20} viewBox="0 0 20 20" width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8.333 1.667H5a1.667 1.667 0 0 0-1.667 1.666v13.332A1.667 1.667 0 0 0 5 18.332h9.999a1.667 1.667 0 0 0 1.666-1.667V10m-5.832-8.333L16.665 7.5h-5.832V1.667Z"
			stroke="#2394AB"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.667}
		/>
		<defs>
			<clipPath id="a">
				<path d="M0 0h19.999v19.999H0z" fill="#fff" />
			</clipPath>
		</defs>
	</svg>
);

export default FileIcon;
