import * as React from 'react';

function PhoneIcon({ stroke = '#37B899', ...props }) {
	return (
		<svg fill="none" height={21} viewBox="0 0 22 21" width={22} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.352.75a7.971 7.971 0 017.04 7.032M13.352 4.293a4.426 4.426 0 013.5 3.5"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.5}
			/>
			<path
				clipRule="evenodd"
				d="M6.7 14.299C-.197 7.4.783 4.241 1.51 3.223c.094-.164 2.396-3.611 4.865-1.589 6.126 5.045-1.63 4.332 3.514 9.477 5.146 5.144 4.431-2.611 9.477 3.514 2.022 2.469-1.425 4.771-1.588 4.864-1.018.728-4.178 1.709-11.078-5.19z"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.5}
			/>
		</svg>
	);
}

export default PhoneIcon;
