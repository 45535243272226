import * as React from 'react';

const BasketIcon = ({ ...props }) => (
	<svg {...props} fill="none" height={22} viewBox="0 0 20 22" width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M12 10v6m-4-6v6M1 5h13m0 0h5m-5 0V3a2 2 0 0 0-2-2H8a2 2 0 0 0-1.732 1M17 8v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		/>
	</svg>
);

export default BasketIcon;
