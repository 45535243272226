import React, { SVGProps } from 'react';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

interface LogoIconInterface extends SVGProps<SVGSVGElement> {
	withColor?: boolean;
	stroke?: string;
}

// @ts-ignore
const FirstPath = ({ stroke }) => {
	const { scrollYProgress } = useViewportScroll();
	const rotate = useTransform(scrollYProgress, [1, 0], [0, 360]);
	return (
		<motion.path
			animate={{ rotate: 360 }}
			d="M57.742 10.052c-26.338 0-47.688 21.35-47.688 47.688s21.35 47.688 47.688 47.688 47.688-21.35 47.688-47.688"
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9.538}
			style={{
				rotate,
			}}
		/>
	);
};

// @ts-ignore
const SecondPath = ({ stroke }) => {
	const { scrollYProgress } = useViewportScroll();
	const rotate = useTransform(scrollYProgress, [1, 0], [360, 0]);
	return (
		<motion.path
			animate={{ rotate: 0 }}
			d="M57.739 86.353c15.801 0 28.613-12.812 28.613-28.613 0-15.802-12.812-28.613-28.613-28.613-15.802 0-28.613 12.811-28.613 28.613"
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={9.538}
			style={{
				rotate,
			}}
		/>
	);
};

function LogoIconAnimation({ withColor, stroke = '#fff', ...props }: LogoIconInterface) {
	return (
		<svg fill="none" viewBox="0 0 115 115" xmlns="http://www.w3.org/2000/svg" {...props}>
			<FirstPath stroke={withColor ? '#37B899' : stroke} />
			<SecondPath stroke={withColor ? '#2394AB' : stroke} />
		</svg>
	);
}

export default LogoIconAnimation;
