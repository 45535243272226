import React from 'react';
import loadable from '@loadable/component';
import { SkeletonView } from '@components/index';

const params = {
	fallback: (
		<div className="h-screen w-1/2">
			<SkeletonView />
		</div>
	),
};

const Auth = {
	SignUp: loadable(() => import('./SignUp'), params),
	Login: loadable(() => import('./Login'), params),
};

export default Auth;
