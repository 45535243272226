import React, { CSSProperties, useRef, useState } from 'react';
import { ArrowIcon, CalendarIcon } from '@assets/icons';
import { Calendar, DefinedRangeProps, DateRangePicker } from 'react-date-range';
import useDeferredRenderEffect from '@hooks/useDeferredRenderEffect';
import moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';
import { useOutside } from '@hooks/useOutside';
import clsx from 'clsx';
import styles from './CustomCalendar.module.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

export enum CalendarVariant {
	DEFAULT,
	LANDING,
}

const variants = {
	init: {
		opacity: 0,
		y: 15,
	},
	enter: {
		opacity: 1,
		y: 10,
		transition: {
			y: {
				ease: 'easeInOut',
				duration: 0.2,
			},
			opacity: {
				ease: 'easeInOut',
				duration: 0,
			},
		},
	},
	exit: {
		y: 15,
		opacity: 0,
	},
};

interface CalendarInterface extends DefinedRangeProps {
	label?: string;
	onDateChange: (p: moment.Moment | undefined | RangeInterface) => void;
	error?: string | null;
	isRange?: boolean;
	customStyles?: string;
	variant?: CalendarVariant;
}

export interface RangeInterface {
	endDate: Date | undefined;
	key: string | undefined;
	startDate: Date | undefined;
}

type months = 'prev' | 'next';

const CustomCalendar = ({
	label,
	onDateChange,
	error,
	ranges,
	variant = CalendarVariant.DEFAULT,
	isRange,
	customStyles,
	...props
}: CalendarInterface) => {
	const [date, setDate] = useState<moment.Moment | undefined>(undefined);
	const [visible, setVisible] = useState(false);

	const [rangeDate, setRangeDate] = useState<RangeInterface>(
		// @ts-ignore
		ranges || {
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	);

	useDeferredRenderEffect(() => {
		if (isRange) {
			onDateChange(rangeDate);
		} else {
			onDateChange(date);
		}
	}, [date, rangeDate]);

	const wrapperRef = useRef(null);
	useOutside(wrapperRef, setVisible);

	const viewDate = () => {
		if (isRange) {
			return `${rangeDate.startDate ? moment(rangeDate.startDate).format('DD MMM YYYY') : ''} - ${
				rangeDate?.endDate ? moment(rangeDate.endDate).format('DD MMM YYYY') : ''
			}`;
		}
		if (date) return moment(date).format('DD MMM YYYY');
		return ' - ';
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const headerView = (
		currFocusedDate: Date,
		changeShownDate: (value: Date | number | string, mode?: 'set' | 'setYear' | 'setMonth' | 'monthOffset') => void,
	) => {
		const handleMonth = (type: months) => {
			const month = {
				next: currFocusedDate.getMonth() + 1,
				prev: currFocusedDate.getMonth() - 1,
			};
			changeShownDate(month[type], 'setMonth');
		};
		return (
			<div className="flex items-center justify-between mb-4">
				<div onClick={() => handleMonth('prev')}>
					<ArrowIcon className="cursor-pointer" height={12} stroke="var(--colors-primary-blue)" width={12} />
				</div>
				<h3 className="text-primary-blue">{moment(currFocusedDate).format('MMMM YYYY')}</h3>
				<div className="p-2 cursor-pointer" onClick={() => handleMonth('next')}>
					<ArrowIcon
						className="transform -rotate-180 cursor-pointer"
						height={12}
						stroke="var(--colors-primary-blue)"
						width={12}
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="relative">
			{label && (
				<p
					className={clsx(
						variant === CalendarVariant.DEFAULT ? 'text-silver-sand mb-1 text-sm' : 'mb-2 text-contrast-color',
					)}
				>
					{label}
				</p>
			)}
			<div className={clsx('relative', variant === CalendarVariant.DEFAULT && 'w-80', error && 'mb-8')}>
				<div
					className={clsx(
						`flex flex-row items-center justify-between cursor-pointer px-3 py-2 rounded-xl ${
							visible ? 'pointer-events-none' : ''
						}`,
						customStyles || 'bg-light-dew',
					)}
					onClick={() => setVisible(!visible)}
				>
					<div className="flex flex-row items-center">
						<CalendarIcon height={28} width={28} />
						<div className="ml-4 text-contrast-color">{viewDate()}</div>
					</div>
					<ArrowIcon className="transform -rotate-90" height={15} stroke="var(--colors-primary-blue)" width={15} />
				</div>
				<AnimatePresence>
					{error && (
						<motion.div
							animate={{ opacity: 1, height: 'auto' }}
							className="w-full"
							exit={{ opacity: 0, height: 0 }}
							initial={{ opacity: 0, height: 0 }}
						>
							<span className="text-xs absolute text-secondary-red">{error}</span>
						</motion.div>
					)}
				</AnimatePresence>
			</div>

			<AnimatePresence>
				{visible && (
					<motion.div
						animate="enter"
						className="flex flex-col items-center absolute z-30 w-full"
						exit="exit"
						initial="init"
						ref={wrapperRef}
						variants={variants}
					>
						{isRange ? (
							<DateRangePicker
								className={styles.calendarRange}
								navigatorRenderer={headerView}
								rangeColors={['var(--colors-primary-blue)']}
								// @ts-ignore
								ranges={[rangeDate]}
								onChange={({ selection }: any) => setRangeDate(selection)}
							/>
						) : (
							<>
								{/* @ts-ignore */}
								<Calendar
									className={styles.calendar}
									color="var(--colors-primary-blue)"
									date={date}
									minDate={new Date()}
									navigatorRenderer={headerView}
									// @ts-ignore
									onChange={(value: any) => setDate(value)}
									// selectRange={selectRange}
									// value={viewValue()}
									// onChange={(value: any) => handlerDate(value)}
									{...props}
								/>
							</>
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default CustomCalendar;
