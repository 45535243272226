import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Button, CustomCalendar, CustomModal, TextInput, TimePicker } from '@components';
import { useFormik } from 'formik';
import { ButtonVariantEnum } from '@components/Button/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@store/auth';
import { useCreateTerminerfassungMutation } from '@api';
import * as yup from 'yup';

export type TermineCreateInitialValues = {
	von: string;
	bis: string;
	termin: Date | null;
	anmerkungen: string;
	code: string | null;
};

const ValidationSchema = yup.object().shape({
	von: yup.string().test('bis not more von', 'Startzeit kann nicht später als Endzeit sein', (value, { parent }) => {
		const { termin, bis } = parent;
		const startDate = moment(termin).format(`DD.MM.YYYY ${value}`);
		if (termin) {
			if (moment(bis, 'HH:mm') < moment(value, 'HH:mm')) {
				return false;
			}
			return moment().format('DD.MM.YYYY HH:mm') < startDate;
		}
		return false;
	}),
	bis: yup.string().test('bis not more von', 'Endzeit kann nicht früher als Startzeit sein', (value, { parent }) => {
		const { termin, von } = parent;
		const startDate = moment(termin).format(`DD.MM.YYYY ${value}`);
		if (termin) {
			if (moment(von, 'HH:mm') > moment(value, 'HH:mm')) {
				return false;
			}
			return moment().format('DD.MM.YYYY HH:mm') < startDate;
		}
		return false;
	}),
	termin: yup.date().label('Datum').nullable().required(),
});

const Erstellen = () => {
	const [visibleCalendar] = useState(false);
	const [visibleModal, setVisibleModal] = useState(false);
	const { code } = useSelector(selectCurrentUser);
	const [createTerminerfassung, { isLoading }] = useCreateTerminerfassungMutation();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const attemptCreateterminerFassung = ({ termin, bis, von, code, anmerkungen }: TermineCreateInitialValues) => {
		const date = moment(termin).format('YYYY-MM-DD');
		const bisTime = moment(bis, 'HH:mm').format('HH:mm:ss');
		const vonTime = moment(von, 'HH:mm').format('HH:mm:ss');
		const values = {
			Kcode: code,
			termin: `${date} 00:00:00`,
			von: `${date} ${vonTime}`,
			bis: `${date} ${bisTime}`,
			anmerkungen,
		};
		return createTerminerfassung(values);
	};

	const { handleSubmit, setFieldValue, values, errors } = useFormik<TermineCreateInitialValues>({
		initialValues: {
			von: '',
			bis: '',
			termin: null,
			anmerkungen: '',
			code,
		},
		validationSchema: ValidationSchema,
		onSubmit: form => attemptCreateterminerFassung(form),
	});

	return (
		<>
			<form className="flex flex-col flex-1 p-8" onSubmit={handleSubmit}>
				<h3 className="text-2xl font-semiBold mb-6 text-center">Erfasssung</h3>
				<div className="mb-6">
					<CustomCalendar
						error={errors.termin}
						label="wähle Datum"
						onDateChange={value => setFieldValue('termin', value)}
					/>
				</div>
				<div className="flex flex-row items-center mb-6">
					<div className="flex-1 mr-3">
						<TimePicker
							className="flex-1"
							error={errors.von}
							label="Von"
							scrollID="left"
							onChange={value => setFieldValue('von', value)}
						/>
					</div>
					<div className="flex-1 ml-3">
						<TimePicker
							className="flex-1"
							error={errors.bis}
							label="Bis"
							scrollID="right"
							onChange={value => setFieldValue('bis', value)}
						/>
					</div>
				</div>
				<div className="mb-6">
					<TextInput
						className="bg-light-dew rounded-xl px-4 py-4 flex-1 max-h-40 leading-6"
						multiline
						value={values.anmerkungen}
						onChange={e => setFieldValue('anmerkungen', e.target.value)}
					/>
				</div>

				<Button
					className="py-3 px-4 w-5/6 mx-auto rounded-full"
					disabled={!!Object.values(errors)?.length || isLoading}
					loading={isLoading}
					text="Neuen Termin speichern"
					type="submit"
				/>
			</form>
			<CustomModal setVisible={setVisibleModal} visible={visibleModal}>
				{visibleCalendar ? (
					<div className="p-8 flex flex-col items-center">
						<Calendar
							className="react-custom-user-calendar"
							value={values.termin}
							onChange={(value: Date) => setFieldValue('termin', value)}
						/>
						<Button
							className="px-4 py-2 rounded-full"
							text="OK"
							variant={ButtonVariantEnum.secondary}
							onClick={() => setVisibleModal(false)}
						/>
					</div>
				) : (
					<div className="w-80 h-80 flex items-center justify-start pt-10 flex-col">
						<span className="text-primary-blue mb-6">Wählen Sie die Zeit</span>
						<Button
							className="px-4 py-2"
							text="OK"
							variant={ButtonVariantEnum.secondary}
							onClick={() => setVisibleModal(false)}
						/>
					</div>
				)}
			</CustomModal>
		</>
	);
};
export default Erstellen;
