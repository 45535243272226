import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ClientStateInterface } from '@store/client/types';
import type { RootState } from '../index';

const initialState = <ClientStateInterface>{
	tracker: {
		von: '',
		id: null,
	},
};

const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		setStartTime: (state, action) => {
			state.tracker = action.payload;
		},
	},
});

export const { setStartTime } = clientSlice.actions;

export default clientSlice.reducer;

export const selectClientState = (state: RootState) => state.client;
export const selectStartTime = createSelector([selectClientState], client => client.tracker);
