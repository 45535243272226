import React, { useCallback } from 'react';
import { useApproveTermineWahlenMutation, useGetTermineWahlenQuery } from '@api';
import { useTermineWahlen } from '@components/Dashboard/TermineWahlenTable/useTermineWahlen';
import { Button, TableComponent } from '@components';
import { ButtonVariantEnum } from '@components/Button/types';
import styles from './TermineWahlenTable.module.scss';

export interface TermineWahlenDashboardInterface {
	anmerkung: string;
	geplanteZeit: string;
	date: string;
	klient: string;
	id: number;
	termin: string;
	von: string;
	bis: string;
}

const headerCols = [
	{
		Header: 'ID',
		accessor: 'id',
	},
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Name',
		accessor: 'klient',
	},
	{
		Header: 'Geplante Zeit',
		accessor: 'geplanteZeit',
	},
];

const TermineWahlenTable = () => {
	const { data, isLoading: getLoading } = useGetTermineWahlenQuery();
	const [convertData] = useTermineWahlen(data);
	const [approveHandler, { isLoading: approveLoading }] = useApproveTermineWahlenMutation();

	const renderRowSubComponent = useCallback(
		({ row: { original } }) => {
			return (
				<div className="flex flex-row items-start bg-white p-4 m-1">
					<span className={styles.label}>Description</span>
					<div className="flex-1 flex flex-row items-start justify-between">
						<span>{original?.anmerkung ? original.anmerkung : 'No description'}</span>
						<Button text="Aufrufen" variant={ButtonVariantEnum.flat} onClick={() => approveHandler(original.id)} />
					</div>
				</div>
			);
		},
		[approveHandler],
	);

	return (
		<TableComponent<TermineWahlenDashboardInterface>
			cols={headerCols}
			data={convertData}
			label="Termine"
			loading={getLoading || approveLoading}
			renderRowSubComponent={renderRowSubComponent}
		/>
	);
};
export default TermineWahlenTable;
