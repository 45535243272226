import React from 'react';
import clsx from 'clsx';
import Loader from '@components/Loader';
import { AnimatePresence, motion } from 'framer-motion';
import { ButtonPropsInterface, ButtonVariantEnum } from './types';
import styles from './Button.module.scss';

const Button: React.FC<ButtonPropsInterface> = ({
	variant = ButtonVariantEnum.default,
	simple = false,
	text,
	className,
	children = null,
	onClick,
	loading,
	...props
}: ButtonPropsInterface) => {
	const buttonClassName = !simple ? clsx(className, styles.btn, styles[variant]) : className;

	return (
		// eslint-disable-next-line react/button-has-type
		<button className={`${buttonClassName} ${loading ? styles.btn__loading : ''}`} {...props} onClick={onClick}>
			<div className="flex flex-row items-center justify-center">
				{text}
				{children && children}
				<AnimatePresence>
					{loading && (
						<motion.div
							animate={{ width: 20, opacity: 1 }}
							className="relative h-full flex items-center justify-end"
							exit={{ width: 0, opacity: 0 }}
							initial={{ width: 0, opacity: 0 }}
							transition={{
								ease: 'easeInOut',
							}}
						>
							<Loader className="absolute left-2" height={20} stroke="#fff" width={20} />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</button>
	);
};

export default Button;
