import { rootSplitApi } from '@api/rootSplitApi';
import { GetTermineParams, ResponseConstructInterface } from '@api/types';
import { SperrzeitenInterface } from '@api/assistant/types';

export interface BerichtResponseInterface {
	aCode: string;
	alreadyHasReport: boolean;
	honorar: number;
	honorarsatz: number;
	sumStdGeplant: number;
	sumStdIst: number;
	terminsHonorarInfo: [];
	zeitRaum: string;
	controversialPercent: string;
}

export const assistantApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getAssistantTermine: builder.query<any, GetTermineParams>({
			query: ({ month = '', status = '' }) => ({
				url: `TermineA/GetAll?Monat=${month}${status || status === '' ? `&status=${status}` : ''}`,
				method: 'GET',
			}),
			providesTags: ['TermineAssistant'],
		}),
		getTermineWahlen: builder.query<any, void>({
			query: () => ({
				url: 'KundentermineWaehlen/Get',
				method: 'GET',
			}),
			providesTags: ['TermineWahlen'],
		}),
		approveTermineWahlen: builder.mutation<any, number>({
			query: id => ({
				url: `KundentermineWaehlen/Select?id=${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['TermineAssistant', 'TermineWahlen', 'Notifications'],
		}),
		getReplacementCandidates: builder.mutation<any, any>({
			query: params => ({
				url: `TermineA/find_replace_candidates`,
				method: 'GET',
				params,
			}),
		}),
		setReplacementCandidates: builder.mutation({
			query: data => ({
				url: `TermineA/set_replacement_candidates`,
				method: 'POST',
				data,
			}),
		}),
		protestAsistantTime: builder.mutation({
			query: data => ({
				url: `TermineA/add_ass_anmerkung`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['TermineAssistant'],
		}),
		getBerichtYears: builder.query<ResponseConstructInterface<BerichtResponseInterface[]>, string>({
			query: date => ({
				url: `HonorarNoten/GetHonorarAssNotes?dauer=${date}`,
				method: 'GET',
			}),
			providesTags: ['Bericht'],
		}),
		getBerichtDataByMonth: builder.mutation<any, string>({
			query: month => ({
				url: `HonorarNoten/GetHonorarNotesTerminInfo?dauer=${month}`,
				method: 'GET',
			}),
		}),
		getSperzeiten: builder.query<SperrzeitenInterface[], string>({
			query: aCode => ({
				url: `Sperrzeiten/GetAll?aCode=${aCode}`,
				method: 'GET',
			}),
			providesTags: ['Sperzeiten'],
		}),
		createSperzeiten: builder.mutation({
			query: data => ({
				url: `ASperrzeitenErfassen/Create`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['Sperzeiten'],
		}),
		removeSperzeiten: builder.mutation({
			query: id => ({
				url: `ASperrzeitenErfassen/Delete?id=${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['Sperzeiten'],
		}),
	}),
});

export const {
	useGetAssistantTermineQuery,
	useGetTermineWahlenQuery,
	useApproveTermineWahlenMutation,
	useGetReplacementCandidatesMutation,
	useSetReplacementCandidatesMutation,
	useProtestAsistantTimeMutation,
	useGetBerichtYearsQuery,
	useGetSperzeitenQuery,
	useCreateSperzeitenMutation,
	useRemoveSperzeitenMutation,
	useGetBerichtDataByMonthMutation,
} = assistantApi;
