import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageSizeOptions } from '@components/TableComponent/TableComponent';
import type { RootState } from '../index';

export type TitleType = {
	title: string;
	id: number | string | null;
};

interface AdminStateInterface {
	userList: {
		pageNumber: string;
		perPage: {
			value: string;
			label: string;
		};
		searchQuery: string;
		id: string | number;
		sorting: string;
	};
	selectedTitle: TitleType;
}

const initialState = <AdminStateInterface>{
	userList: {
		pageNumber: '1',
		perPage: pageSizeOptions[0],
		searchQuery: '',
		id: ' ',
		sorting: '',
	},
	selectedTitle: {
		title: '',
		id: null,
	},
};

const adminSlice = createSlice({
	name: 'admin',
	initialState,
	reducers: {
		setUserListPage: (state, action) => {
			state.userList.pageNumber = action.payload;
		},
		setUserListPageCount: (state, action) => {
			state.userList.perPage = action.payload;
		},
		setUserListSearchQuery: (state, action) => {
			state.userList.searchQuery = action.payload;
			state.userList.pageNumber = initialState.userList.pageNumber;
		},
		setUserRoleId: (state, action) => {
			state.userList.id = action.payload;
			state.userList.pageNumber = initialState.userList.pageNumber;
		},
		setUserSorting: (state, action) => {
			state.userList.sorting = action.payload;
		},
		setTitle: (state, action) => {
			state.selectedTitle = action.payload;
		},
	},
});

export const { setUserListPage, setUserListPageCount, setUserListSearchQuery, setUserRoleId, setTitle } =
	adminSlice.actions;

export default adminSlice.reducer;

export const selectAdminState = (state: RootState) => state.admin;
export const selectAdminUserList = createSelector([selectAdminState], admin => admin.userList);
export const selectSelectedTitle = createSelector([selectAdminState], admin => admin.selectedTitle);
