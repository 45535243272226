import React, { useEffect } from 'react';
import Modal from 'react-modal';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { CrossIcon } from '@assets/icons';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './CustomModal.module.scss';

interface CustomModal {
	children: React.ReactNode;
	visible: boolean;
	setVisible: (b: boolean) => any;
	// eslint-disable-next-line react/no-unused-prop-types
	otherStyles?: { [key: string]: string };
	className?: string;
}

const customStyles = {
	content: {
		width: '100%',
		height: '100%',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		padding: 0,
		overflow: 'initial',
		background: 'transparent',
	},
	overlay: {
		zIndex: 30,
		background: 'transparent',
		width: '100%',
		height: '100%',
	},
};

const CustomModal = ({ visible = false, setVisible, children, className }: CustomModal) => {
	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden';
		}
		if (!visible) {
			document.body.style.overflow = 'unset';
		}
	}, [visible]);

	return (
		<AnimatePresence>
			{visible && (
				<Modal ariaHideApp={false} bodyOpenClassName="" isOpen={visible} style={customStyles}>
					<motion.div
						animate={{
							opacity: 1,
							transition: {
								type: 'easyInOut',
								stiffness: 300,
							},
						}}
						className="bg-black w-full h-screen flex justify-center items-center bg-opacity-40"
						exit={{
							opacity: 0,
							transition: { duration: 0.3 },
						}}
						initial={{
							opacity: 0,
						}}
					>
						<motion.div
							animate={{
								opacity: 1,
								y: 0,
								scale: 1,
							}}
							className={clsx('relative z-30 bg-white rounded-md', className || styles.modal)}
							exit={{
								opacity: 0,
								scale: 0.5,
								transition: { duration: 0.6 },
							}}
							initial={{
								opacity: 0,
								y: 60,
								scale: 0.5,
							}}
						>
							<div className="p-2 absolute right-5 top-5 cursor-pointer z-10" onClick={() => setVisible(false)}>
								<CrossIcon height={25} stroke="var(--colors-primary-blue)" width={25} />
							</div>
							{children}
						</motion.div>
					</motion.div>
				</Modal>
			)}
		</AnimatePresence>
	);
};
export default CustomModal;
