import * as React from 'react';

function TopLeftRight({ ...props }) {
	return (
		<svg fill="none" viewBox="0 0 610 409" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				clipRule="evenodd"
				d="M409.467-203.718C463.504-159.778 514.52-102.625 521.3-33.309c6.919 70.73-25.791 143.451-76.236 193.51-46.247 45.894-120.982 34.923-180.551 61.316-66.352 29.398-117.609 123.774-186.638 101.372-69.78-22.646-62.08-126.978-94.716-192.681-24.08-48.478-70.844-87.348-71.27-141.475-.42-53.567 35.647-98.868 69.052-140.745 30.934-38.781 68.5-70.635 114.458-89.31 47.338-19.236 97.773-23.622 148.507-17.548 59.205 7.089 119.298 17.534 165.561 55.152z"
				fill="#18859B"
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default TopLeftRight;
