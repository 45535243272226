import * as React from 'react';

function CrossIcon({ stroke = '#fff', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M24 8l-5.333 5.333M16 16l-8 8m8-8L8 8m8 8l8 8"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default CrossIcon;
