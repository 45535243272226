import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import { rootSplitApiLanding } from '@landing/api/rootSplitApiLanding';
import { RootState } from '@store/index';
import { landingApi } from '@landing/api/landing';

const rootConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [rootSplitApiLanding.reducerPath],
};

const combinedReducer = combineReducers({
	[rootSplitApiLanding.reducerPath]: rootSplitApiLanding.reducer,
	news: persistReducer(rootConfig, landingApi.reducer),
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'auth/signOut') {
		// eslint-disable-next-line no-param-reassign
		state = {} as RootState;
		storage.removeItem('persist:root');
		window.location.reload();
	}
	return combinedReducer(state, action);
};

export const landingStore = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		[
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}),
			rootSplitApiLanding.middleware,
		].flat(),
});

export const landingPersist = persistStore(landingStore);
