import React from 'react';
import clsx from 'clsx';

interface LinkWrapperInterface {
	children: React.ReactNode;
	customStyles?: any;
	link?: string;
	onClick?: () => void;
}

const LinkWrapper = ({ children, link, customStyles, onClick }: LinkWrapperInterface) => {
	return (
		<a
			className={clsx(
				'rounded-lg transition-opacity hover:opacity-80 cursor-pointer duration-500 ease-in-out',
				customStyles,
			)}
			href={link}
			onClick={onClick}
		>
			{children}
		</a>
	);
};

export default LinkWrapper;
