import * as React from 'react';

function GeoPositionArrow({ ...props }) {
	return (
		<svg fill="none" height={24} width={25} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M11.5 13l2 8 9-19-19 9 5 1.25"
				stroke="#2394AB"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default GeoPositionArrow;
