import React, { createContext, useState } from 'react';
import { Modal } from '@components/Modals';

interface ModalContextInterface {
	modal?: boolean;
	handleModal: (p?: string) => void;
	setIsSuccess?: (p: boolean) => void;
	isSuccess?: boolean;
	modalContent?: string;
}

export const ModalContext = createContext<ModalContextInterface>({
	modal: false,
	handleModal: () => {},
	isSuccess: false,
});

export const ModalProvider: React.FC = ({ children }) => {
	const [modal, setModal] = useState(false);
	const [modalContent, setModalContent] = useState("I'm the Modal Content");

	const handleModal = (content = '') => {
		if (content) {
			setModalContent(content);
		}
		return setModal(!modal);
	};

	return (
		<ModalContext.Provider value={{ modal, handleModal, modalContent }}>
			<Modal />
			{children}
		</ModalContext.Provider>
	);
};
