import React from 'react';
import clsx from 'clsx';

interface BoxShadowInterface {
	children: React.ReactNode;
	className?: string;
}

const BoxShadow = ({ children, className }: BoxShadowInterface) => {
	return <div className={clsx('shadow border border-transparent', className)}>{children}</div>;
};
export default BoxShadow;
