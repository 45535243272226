import React, { HTMLAttributes } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import clsx from 'clsx';
import styles from './SkeletonView.module.scss';

interface SkeletonView {
	highlightColor?: string;
	color?: string;
	props?: HTMLAttributes<HTMLDivElement>;
	height?: string | number;
	width?: string | number;
	className?: string;
}

const SkeletonView = ({
	color = '#fff',
	height = '100%',
	width = '100%',
	highlightColor,
	className,
	...props
}: SkeletonView) => {
	return (
		<div
			className={clsx(className, styles.skeleton, 'overflow-hidden')}
			style={{
				height,
				width,
			}}
			{...props}
		>
			{/* <SkeletonTheme color={color} highlightColor={highlightColor}> */}
			{/*	<Skeleton /> */}
			{/* </SkeletonTheme> */}
		</div>
	);
};

export default SkeletonView;
