import * as React from 'react';

const DocIcon = ({ stroke = '#2394AB', ...props }) => (
	<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16 17H8M16 13H8M10 9H8M10 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8M13 2l7 7h-7V2Z"
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
		/>
	</svg>
);

export default DocIcon;
