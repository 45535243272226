import React from 'react';
import { FileIcon } from '@assets/icons';

export interface FileLinkProps {
	name: string;
	href: string;
}

const FileLink: React.FC<FileLinkProps> = ({ name, href }) => {
	return (
		<a className="p-2 flex rounded-3xl flex-row items-center" href={href} target="blank">
			<FileIcon />
			<span className="text-contrast-color ml-2">{name}</span>
		</a>
	);
};
export default FileLink;
