import React from 'react';
import loadable from '@loadable/component';
import Loader from '@components/Loader';

const params = {
	fallback: (
		<div className="container mx-auto flex-grow flex items-center justify-center flex-1">
			<Loader />
		</div>
	),
};

const Landing = {
	HomeLanding: loadable(() => import('./HomeLanding'), params),
	Service: loadable(() => import('./Service'), params),
	DataProtection: loadable(() => import('./DataProtection'), params),
	Faq: loadable(() => import('./Faq'), params),
	PersonalAssistance: loadable(() => import('./PersonalAssistance'), params),
	NotFound: loadable(() => import('../../components/Static/NotFound'), params),
};

export default Landing;
