import * as React from 'react';

function BottomRight({ ...props }) {
	return (
		<svg fill="none" viewBox="0 0 221 290" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				clipRule="evenodd"
				d="M444.458 348.217c-19.459 52.686-48 108.166-91.85 138.146-44.745 30.591-97.423 35.077-138.368 19.252-37.537-14.508-42.943-69.135-70.057-99.807-30.202-34.165-100.044-34.307-97.076-89.257 3.001-55.548 72.004-88.546 109.175-134.712 27.426-34.063 44.814-79.967 79.872-100.08 34.694-19.904 70.14-12.073 102.918-4.79 30.354 6.743 57.324 20.515 77.148 44.797 20.419 25.01 31.719 57.556 36.281 94.138 5.323 42.688 8.617 87.207-8.043 132.313z"
				fill="#87D0CE"
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default BottomRight;
