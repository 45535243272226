import './styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { persist, store } from '@store/index';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Drawer } from '@components';
import { ModalProvider } from '@contexts/ModalContext';
import { Toaster } from '@components/Modals';
import { landingPersist, landingStore } from '@landing/store';
import App from './App';
import AppLanding from './_landing/App';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_TYPE === 'web') {
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persist}>
					<ModalProvider>
						<Toaster />
						<App />
						<Drawer />
					</ModalProvider>
				</PersistGate>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root'),
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<Toaster />
			<Provider store={landingStore}>
				<PersistGate loading={null} persistor={landingPersist}>
					<AppLanding />
				</PersistGate>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root'),
	);
}

reportWebVitals();
