import * as React from 'react';

function SuccessIcon({ fill = '#5243C2', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M11.286 1.834l-5.704 7.74a.843.843 0 01-1.192.157L.317 6.474a.834.834 0 011.041-1.301L4.755 7.89 9.945.848a.833.833 0 111.34.986z"
				fill={fill}
			/>
		</svg>
	);
}

export default SuccessIcon;
