import React from 'react';
import BenachrichtigungenIcon from '@assets/icons/menu/BenachrichtigungenIcon';
import clsx from 'clsx';
import { AccountIcon } from '@assets/icons';
import { useAuth } from '@hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserRole } from '@store/auth';
import { selectDrawerSlider, setStateDrawer } from '@store/main';
import { useHistory } from 'react-router-dom';
import { USER_ROLES } from '@utils/consts';
import styles from './Header.module.scss';

const Header = () => {
	const {
		user: { firstName, lastName },
	} = useAuth();
	const role = useSelector(selectUserRole);
	const dispatch = useDispatch();
	const { name } = useSelector(selectDrawerSlider);
	const history = useHistory();

	return (
		<div className={clsx(styles.header, 'flex flex-row justify-between mb-6')}>
			{role !== USER_ROLES.USER_ROLE && (
				<div
					className="flex flex-row items-center cursor-pointer"
					onClick={() => dispatch(setStateDrawer({ name, state: true }))}
				>
					<div className="relative mr-4">
						<BenachrichtigungenIcon className="stroke-current text-primary-blue" height={30} width={30} />
						<div className="bg-secondary-red h-4 w-4 rounded-full absolute -top-1 right-1" />
					</div>
					<span className="text-contrast-color">Benachrichtigungen</span>
				</div>
			)}
			<div className="flex flex-row items-center cursor-pointer ml-auto" onClick={() => history.push('Profile')}>
				<div className="rounded-full bg-primary-blue-green bg-opacity-10 p-2 mr-2">
					<AccountIcon height={35} width={35} />
				</div>
				<div className="flex flex-col justify-center">
					<span>{`${firstName || 'User'} - ${lastName || 'User'}`}</span>
					<span className="text-sm text-contrast-color text-opacity-30">{role}</span>
				</div>
			</div>
		</div>
	);
};
export default Header;
