import React, { useMemo } from 'react';
import { AssistantDataInterface } from '@components/Tables/TermineAssistantTable/useAssistantDataTable';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUserRole } from '@store/auth';
import { USER_ROLES } from '@utils/consts';
import { Button, TimePicker } from '@components/index';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { formatTime } from '@utils/helpers';
import { TextInput } from '@components/Form';
import { useProtestAsistantTimeMutation } from '@api';
import { useProtestClientTimeMutation } from '@api/client';
import styles from './Protest.module.scss';

const Protest = (props: AssistantDataInterface) => {
	const role = useSelector(selectUserRole);

	const [protestAssistant, { isLoading: assistantLoading }] = useProtestAsistantTimeMutation();
	const [protestClient] = useProtestClientTimeMutation();

	const isClient = useMemo(() => role === USER_ROLES.CLIENT_ROLE || role === USER_ROLES.CLIENT_SECOND_ROLE, [role]);

	const {
		assISTVon,
		assISTBis,
		id,
		assAnmerkungen,
		anmerkung,
		klient,
		totalTerminTime,
		loggedTimeVon,
		loggedTimeBis,
		von,
		bis,
		date,
	} = props;

	const nameVonField = isClient ? 'loggedTimeVon' : 'assISTVon';
	const nameCommentField = isClient ? 'anmerkungen' : 'assAnmerkungen';
	const nameBisField = isClient ? 'loggedTimeBis' : 'assISTBis';
	const label = isClient ? ' Zeit ändern' : 'Einspruch';

	const validationSchema = yup.object().shape({
		[nameVonField]: yup
			.string()
			.test('bis not more von', 'Startzeit kann nicht später als Endzeit sein', (value, { parent }) => {
				const bisField = parent[nameBisField];

				return formatTime(value, 'HH:mm') <= formatTime(bisField, 'HH:mm');
			}),
		[nameBisField]: yup
			.string()
			.test('bis not more von', 'Endzeit kann nicht früher als Startzeit sein', (value, { parent }) => {
				const vonField = parent[nameVonField];

				return formatTime(value, 'HH:mm') >= formatTime(vonField, 'HH:mm');
			}),
	});

	const initialValues = useMemo(
		() => ({
			// eslint-disable-next-line react/destructuring-assignment
			[nameVonField]: props[nameVonField],
			// eslint-disable-next-line react/destructuring-assignment
			[nameBisField]: props[nameBisField],
			termineId: id,
			[nameCommentField]: isClient ? anmerkung : assAnmerkungen,
		}),
		[anmerkung, assAnmerkungen, id, nameBisField, nameCommentField, nameVonField, props, role],
	);

	const { handleSubmit, setFieldValue, values, errors } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: val => (isClient ? protestClient(val) : protestAssistant(val)),
	});

	const totalAssistTime =
		assISTVon && assISTBis
			? moment(moment(assISTVon, 'HH:mm:ss').diff(moment(assISTBis, 'HH:mm:ss'))).format('HH:mm')
			: formatTime(totalTerminTime, 'HH:mm:ss');

	const totalTime =
		role === (USER_ROLES.CLIENT_ROLE || USER_ROLES.CLIENT_SECOND_ROLE) ? totalTerminTime : totalAssistTime;

	return (
		<form className={`bg-white rounded-xl flex flex-col py-6 ${styles.protest}`} onSubmit={handleSubmit}>
			<h3 className="text-2xl text-center font-semiBold mb-8 text-contrast-color">{label}</h3>
			<div className="bg-light-gray flex flex-row items-center justify-between px-8 py-4 mb-8">
				<div className="flex flex-col items-start">
					<span className="text-silver-sand text-xl">Ist - Zeit</span>
					<span className="text-primary-blue-green text-5xl font-semiBold mb-1">{totalTime}</span>
					{loggedTimeVon && loggedTimeBis && (
						<span>{`${formatTime(loggedTimeVon, 'HH:mm:ss')} - ${formatTime(loggedTimeBis, 'HH:mm:ss')}`}</span>
					)}
				</div>
				<div className="flex flex-col items-end">
					<span className="mb-3">{klient}</span>
					<p className="mb-2">
						<span className="text-silver-sand text-sm mr-2">Date </span>
						<span>{date}</span>
					</p>
					<p className="mb-2">
						<span className="text-silver-sand text-sm mr-2">Geplante Zeit </span>
						<span>{`${von} - ${bis}`}</span>
					</p>
					{assISTVon && assISTBis && (
						<p className="mb-2">
							<span className="text-silver-sand text-sm mr-2">Assistant Zeit </span>
							<span>{`${formatTime(assISTVon, 'HH:mm:ss')} - ${formatTime(assISTBis, 'HH:mm:ss')}`}</span>
						</p>
					)}
				</div>
			</div>
			<div className="px-8">
				<div className="flex flex-row justify-between mb-4">
					<TimePicker
						className="flex-1 mr-3"
						error={errors[nameVonField]}
						label="Von"
						scrollID="left"
						time={values[nameVonField]}
						onChange={value => setFieldValue(nameVonField, value)}
					/>
					<TimePicker
						className="flex-1 ml-3"
						error={errors[nameBisField]}
						label="Bis"
						scrollID="left"
						time={values[nameBisField]}
						onChange={value => setFieldValue(nameBisField, value)}
					/>
				</div>
				<div className="mb-6">
					<TextInput
						className="bg-light-dew rounded-xl px-4 py-4 flex-1 max-h-40 leading-6"
						error={errors[nameCommentField]}
						multiline
						// @ts-ignore
						value={values[nameCommentField]}
						onChange={e => setFieldValue(nameCommentField, e.target.value)}
					/>
				</div>

				<div className="flex">
					<Button
						className="py-3 px-6 rounded-full mx-auto"
						disabled={!!Object.values(errors).length || assistantLoading}
						loading={assistantLoading}
						text="Speichern"
						type="submit"
					/>
				</div>
			</div>
		</form>
	);
};

export default Protest;
