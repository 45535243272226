import { rootSplitApi } from '@api/rootSplitApi';
import { ResponseConstructInterface } from '@api/types';
import { RoleType } from '@api/user';

export interface UsersDataResponseInterface {
	result: UserDataItemInterface[];
	totalCount: number;
}

export interface UserDataItemInterface {
	email: string;
	id: string;
	userCode: string;
	userName: string;
	userRole: {
		id: string;
		name: RoleType;
	};
}

export interface UsersDataParams {
	pageNumber?: string;
	perPage?: string;
	searchQuery?: string;
	sortBy?: string;
	id?: number | string;
}

export interface UserRoleInterface {
	id: number;
	name: RoleType;
}

export interface TitleInterface {
	title: string;
	id: number | null;
}

type userCodeType = 'aCode' | 'kCode';

export type UserCodeKeyType = {
	[key in userCodeType]: string;
};

interface UserDataInterface extends UserCodeKeyType {
	vorname: string;
	zuname: string;
	email: string;
}

export interface UserResponseDataInterface {
	role: UserRoleInterface;
	userData: UserDataInterface;
}

export interface RequestCheckUserCode {
	role: RoleType;
	code: string;
}

export interface CheckUserCodeInterface {
	check: any;
	sendData: any;
}

export interface CreateTitleParamsInterface {
	name: string;
}

export interface UpdateTitleParamsInterface {
	name: string;
	id: number | null | undefined;
}

const sendDataConfig = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};

export const adminApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getUsers: builder.query<ResponseConstructInterface<UsersDataResponseInterface> | any, UsersDataParams>({
			query: ({ pageNumber, perPage, searchQuery, sortBy, id }) => ({
				url: `users/get_web?page=${pageNumber}
				${perPage ? `&perPage=${perPage}` : ''}
				${id === 0 || id ? `&id=${id}` : `&id=`}
				${sortBy ? `&sortBy=${sortBy}` : ''}
				${searchQuery ? `&SearchQuery=${searchQuery}` : ''}`,
				method: 'GET',
			}),
			providesTags: ['AdminUsers'],
		}),
		getAllUserRoles: builder.query<ResponseConstructInterface<UserRoleInterface[]> | any, any>({
			query: () => ({
				url: `user_roles/get`,
				method: 'GET',
			}),
			providesTags: ['AdminUserRoles'],
		}),
		getUserEmailId: builder.query<ResponseConstructInterface<UserResponseDataInterface>, any>({
			query: email => ({
				url: `users/get_by_email?data.email=${email}`,
				method: 'GET',
			}),
		}),
		setUserRole: builder.mutation<any, any>({
			query: ({ role, data }) => ({
				url: `users/set_to_role/${role}`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminUsers'],
		}),
		updateUserInfo: builder.mutation<any, any>({
			query: ({ role, data }) => ({
				url: `users/update_user_info/${role}`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminUsers'],
		}),
		getNewsTitles: builder.query<ResponseConstructInterface<TitleInterface[]>, any>({
			query: () => ({
				url: `dynamic_info_content/get_page_titles`,
				method: 'GET',
			}),
			providesTags: ['AdminNewsTitle'],
		}),
		getNewsInfo: builder.query({
			query: () => ({
				url: 'dynamic_info_content/get_news_web',
				method: 'GET',
			}),
		}),
		handleNews: builder.mutation({
			query: data => ({
				url: `dynamic_info_content/edit_news`,
				method: 'POST',
				data,
			}),
		}),
		removeUser: builder.mutation({
			query: data => ({
				url: `Account/delete`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminUsers'],
		}),
		getNewsByTitle: builder.query({
			async queryFn({ id }, api, extraOptions, fetchWithBQ) {
				if (!id) {
					try {
						const firstTitle = await fetchWithBQ({ url: 'dynamic_info_content/get_page_titles', method: 'GET' });

						const queryByFirstId = await fetchWithBQ({
							// @ts-ignore
							url: `dynamic_info_content/get_news_by_title?titleId`,
							method: 'GET',
							// @ts-ignore
							params: firstTitle?.data[0].id,
						});
						return queryByFirstId.data ? { data: queryByFirstId.data } : { error: queryByFirstId.error };
					} catch (error) {
						return { error };
					}
				}
				try {
					const queryByTitle = await fetchWithBQ({
						url: `dynamic_info_content/get_news_by_title?titleId=${id}`,
						method: 'GET',
					});

					return queryByTitle.data ? { data: queryByTitle.data } : { error: queryByTitle.error };
				} catch (error) {
					return { error };
				}
			},
			providesTags: ['AdminNews']
		}),
		createNewsTitle: builder.mutation<any, CreateTitleParamsInterface>({
			query: data => ({
				url: `dynamic_info_content/create_title`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminNewsTitle'],
		}),
		updateNewsTitle: builder.mutation<any, UpdateTitleParamsInterface>({
			query: data => ({
				url: `dynamic_info_content/edit_title`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminNewsTitle'],
		}),
		removeNewsTitle: builder.mutation<any, any>({
			query: data => ({
				url: `dynamic_info_content/delete_title`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['AdminNewsTitle'],
		}),
		uploadFiles: builder.mutation<any, any>({
			query: data => ({
				url: `dynamic_info_content/attach_upload`,
				method: 'POST',
				data,
				sendDataConfig,
			}),
			invalidatesTags: ['AdminNews'],
		}),
		editNews: builder.mutation<any, any>({
			query: data => ({
				url: `dynamic_info_content/edit_news_web`,
				method: 'POST',
				data,
				sendDataConfig,
			}),
			invalidatesTags: ['AdminNewsByTitle', 'AdminNews'],
		}),
		removeNews: builder.mutation<any, any>({
			query: id => ({
				url: `dynamic_info_content/delete_news`,
				method: 'POST',
				data: { id },
			}),
			invalidatesTags: ['AdminNewsByTitle', 'AdminNews'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetUsersQuery,
	useGetAllUserRolesQuery,
	useGetUserEmailIdQuery,
	useSetUserRoleMutation,
	useUpdateUserInfoMutation,
	useGetNewsTitlesQuery,
	useCreateNewsTitleMutation,
	useUpdateNewsTitleMutation,
	useUploadFilesMutation,
	useGetNewsByTitleQuery,
	useRemoveNewsTitleMutation,
	useEditNewsMutation,
	useRemoveNewsMutation,
	useGetNewsInfoQuery,
	useRemoveUserMutation,
} = adminApi;
