import loadable from '@loadable/component';

const Root = {
	Home: loadable(() => import('./Home')),
	Documentation: loadable(() => import('./Documentation')),
	Contacts: loadable(() => import('./Contacts')),
	Benachrichtigungen: loadable(() => import('./Benachrichtigungen')),
	Profile: loadable(() => import('./Profile')),
};

export default Root;
