import React from 'react';

const NotFound = () => {
	return (
		<div className="container m-auto flex-grow flex items-center justify-center h-full flex-1 flex-col">
			<p className="text-9xl text-contrast-raven">404</p>
			<p className="text-xl text-contrast-raven">Nicht gefunden</p>
		</div>
	);
};
export default NotFound;
