import loadable from '@loadable/component';

const Assistant = {
	TermineWahlen: loadable(() => import('./TermineWahlen')),
	Bericht: loadable(() => import('./Bericht')),
	Sperzeiten: loadable(() => import('./Sperzeiten')),
	Termine: loadable(() => import('./TermineAsssistant')),
};

export default Assistant;
