import * as React from 'react';

function TerminerfassungIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6 10h15V6a2 2 0 00-2-2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7m-4 5h-4M16 1v3M8 1v3"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default TerminerfassungIcon;
