import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserRole } from '@store/auth';
import { USER_ROLES } from '@utils/consts';
import { viewExistData } from '@utils/helpers';
import { NotificationComponentWithTypeInterface } from '@components/Notifications/Notification.types';

const NotificationNotReplacement = ({ termine }: NotificationComponentWithTypeInterface) => {
	const role = useSelector(selectUserRole);
	const keyWord =
		// eslint-disable-next-line no-nested-ternary
		role === USER_ROLES.ASSISTANT_ROLE
			? 'aAnschrift'
			: role === USER_ROLES.CLIENT_ROLE || role === USER_ROLES.CLIENT_SECOND_ROLE
			? 'kAnschrift'
			: null;

	return (
		<>
			<h5 className="mb-4 text-secondary-red text-lg">Kein Ersatz gefunden </h5>
			<div className="flex flex-row items-start">
				<div className="flex flex-col flex-1 ml-4">
					<div className="mb-4 flex flex-row">
						<span className="text-gray-main">Der Assistent findet einen Ersatz oder kommt zur Besprechung </span>
					</div>
					<div className="flex flex-row items-start justify-between">
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">Date</span>
							<span className="text-contrast-raven">
								{viewExistData(!!termine?.terminVonBis, termine?.terminVonBis.split(' ')[0])}
							</span>
						</div>
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">Geplante Zeit</span>
							<span className="text-contrast-raven">
								{viewExistData(!!termine?.von && !!termine?.bis, `${termine?.von} - ${termine?.bis}`)}
							</span>
						</div>
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">die Anschrift</span>
							<span className="text-contrast-raven">
								{viewExistData(!!keyWord && !!termine[keyWord], termine[keyWord || 'aAnschrift'])}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotificationNotReplacement;
