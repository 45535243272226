export const USER_ROLES = {
	CLIENT_ROLE: 'KlientenTE',
	CLIENT_SECOND_ROLE: 'Klienten',
	ASSISTANT_ROLE: 'Assistenten',
	ADMIN_ROLE: 'Admin',
	USER_ROLE: 'User',
} as const;

export const CLIENT_ROLE = USER_ROLES.CLIENT_ROLE || USER_ROLES.CLIENT_ROLE;

export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d()^@$!%*#?&-_=+]{6,}$/;

export const rootPath = window.location.origin;
export const positionAuthLeftStyles = window.innerWidth / window.innerHeight > 1.77 ? 'pr-60 ml-auto' : 'mx-auto';
export const positionAuthRightStyles = window.innerWidth / window.innerHeight > 1.77 ? 'pl-60 mr-auto' : 'mx-auto';

export const landingNews = [
	{
		src: '/img/landing/news/1.png',
		title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
		text: 'Die PAV, Persönliche Assistenz gemeinnützige GmbH, organisiert Persönliche Assistenz für Menschen mit Behinderung in ganz Wien.',
	},
	{
		src: '/img/landing/news/2.png',
		title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
		text: 'Die PAV, Persönliche Assistenz gemeinnützige GmbH, organisiert Persönliche Assistenz für Menschen mit Behinderung in ganz Wien.',
	},
	{
		src: '/img/landing/news/3.png',
		title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
		text: 'Die PAV, Persönliche Assistenz gemeinnützige GmbH, organisiert Persönliche Assistenz für Menschen mit Behinderung in ganz Wien.',
	},
	{
		src: '/img/landing/news/4.png',
		title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
		text: 'Die PAV, Persönliche Assistenz gemeinnützige GmbH, organisiert Persönliche Assistenz für Menschen mit Behinderung in ganz Wien.',
	},
];

export const landingAllNews = [
	[
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: '(Montag,  15.01.2022)',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: '(Montag,  16.01.2022)',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: '(Montag,  17.01.2022)',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: '(Montag,  18.01.2022)',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: '(Montag,  19.01.2022)',
		},
	],
	[
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 21',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 22',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 23',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 24',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 25',
		},
	],
	[
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 27',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 28',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 29',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 30',
		},
		{
			title: 'Wir suchen laufend Persönliche AssistentInnen für Wien',
			date: 'Monday,  Jan 31',
		},
	],
];

export const questionsAndAnswers = [
	{
		title: 'Muss ich Persönliche Assistenz privat bezahlen oder von meinem Pflegegeld?',
		text: 'Nein. Beim FSW reicht man eine Selbsteinschätzung und einen Antrag auf„Pflegegeldergänzungsleistung“ ein. Wenn man die Bewilligung vom FSW bekommt, dann erhält man zusätzlich zum Pflegegeld ein monatliches Budget, das nachweislich für Persönliche Assistenz ausgegeben werden kann.',
	},
];

export const headerLinks = [
	{
		title: 'Für Kunden',
		scrollId: 'kunden',
		offset: -80,
	},
	{
		title: 'Aktuelles',
		scrollId: 'aktuelles',
		offset: 0,
	},
	{
		title: 'Für Mitarbeiter ',
		scrollId: 'mitarbeiter',
		offset: -100,
	},
	{
		title: 'Jobs',
		scrollId: 'jobs',
		offset: -50,
	},
	{
		title: 'Über PAV',
		scrollId: 'uber',
		offset: -50,
	},
	{
		title: 'Kontakt',
		scrollId: 'contacts',
		offset: 0,
	},
] as const;

export const statuses = [
	{
		label: 'All',
		value: '',
	},
	{
		value: 'bestatigt',
		label: 'Bestatigt',
	},
	{
		value: 'nicht_passiert',
		label: 'Nicht passiert',
	},
	{
		value: 'widerspruch',
		label: 'Widerspruch',
	},
	{
		value: 'unbestatigt',
		label: 'Unbestatigt',
	},
	{
		value: 'abgerechnet',
		label: 'Abgerechnet',
	},
	{
		value: 'nicht_abgerechnet',
		label: 'Nicht abgerechnet',
	},
];

export const landingProdUrl = 'https://pav-landing.mooo.com';
export const webProdUrl = 'https://pav-wien.mooo.com';
export const WIDTH_MENU = 270;

export const fileDynamicData = {
	'image/jpg': {
		icon: 'imageIcon',
		uri: 'data:image/jpg;base64,',
		ext: 'jpg',
	},
	'image/jpeg': {
		icon: 'imageIcon',
		uri: 'data:image/jpeg;base64,',
		ext: 'jpeg',
	},
	'image/png': {
		icon: 'imageIcon',
		uri: 'data:image/png;base64,',
		ext: 'png',
	},
	'application/doc': {
		icon: 'documentIcon',
		uri: 'data:application/msword;base64,',
		ext: 'doc',
	},
	'application/docx': {
		icon: 'documentIcon',
		uri: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
		ext: 'docx',
	},
	'application/pdf': {
		icon: 'documentIcon',
		uri: 'data:application/pdf;base64,',
		ext: 'pdf',
	},
	'application/xls': {
		icon: 'documentIcon',
		uri: 'data:application/vnd.ms-excel;base64,',
		ext: 'xls',
	},
	'application/xlsx': {
		icon: 'documentIcon',
		uri: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
		ext: 'xlsx',
	},
};
