import * as React from 'react';

const ArrowSharpIcon = ({ ...props }) => (
	<svg fill="none" height={12} viewBox="0 0 12 12" width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			clipRule="evenodd"
			d="M6.743 1.063v8.378l3.66-3.66a.756.756 0 0 1 1.064 0 .747.747 0 0 1 0 1.057l-4.942 4.943a.747.747 0 0 1-1.057 0L.525 6.838a.747.747 0 1 1 1.058-1.057l3.66 3.66V1.063c0-.412.337-.75.75-.75.412 0 .75.338.75.75Z"
			fill="#A5A5A5"
			fillRule="evenodd"
		/>
	</svg>
);

export default ArrowSharpIcon;
