import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LogoIcon } from '@assets/icons';
import { LogoutIcon } from '@assets/icons/menu';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { WIDTH_MENU } from '@utils/consts';
import { signOut } from '@store/auth';

const LeftMenu = ({ routes }: any) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const currentPage = pathname === '/' ? 'home' : pathname.split('/')[1];

	const logout = () => {
		dispatch(signOut());
	};
	return (
		<div className="flex flex-col justify-between pb-10 bg-white" style={{ width: WIDTH_MENU }}>
			<div>
				<div className="flex flex-row items-center mb-8 p-4">
					<LogoIcon height={60} width={60} withColor />
					<p className="text-contrast-color text-3xl ml-2">PAV WIEN</p>
				</div>
				<ul className="flex flex-col overflow-hidden">
					{routes
						.filter((item: { Icon: any }) => item?.Icon)
						.map(({ name, path, Icon }: any, index: React.Key | null | undefined) => {
							return (
								<motion.li key={index} whileHover={{ translateX: 8 }}>
									<Link
										className={`py-3 px-4 flex flex-row items-center transition group hover:bg-primary-green hover:bg-opacity-5 ${
											currentPage === name && 'bg-primary-green bg-opacity-5'
										}`}
										key={index}
										to={path}
									>
										{Icon && (
											<Icon
												className={`stroke-current transition group-hover:text-primary-blue ${
													currentPage === name ? 'text-primary-blue' : 'text-contrast-color'
												}`}
												height={25}
												width={25}
											/>
										)}
										<span
											className={`ml-2 transition group-hover:text-primary-blue capitalize ${
												currentPage === name ? 'text-primary-blue' : 'text-contrast-color'
											}`}
										>
											{name}
										</span>
									</Link>
								</motion.li>
							);
						})}
				</ul>
			</div>
			<div
				className="flex flex-row items-center py-3 px-4 group transition hover:bg-primary-green hover:bg-opacity-5 cursor-pointer"
				onClick={() => logout()}
			>
				<LogoutIcon
					className="stroke-current text-contrast-raven transition group-hover:text-primary-blue"
					height={25}
					width={25}
				/>
				<span className="text-contrast-color transition group-hover:text-primary-blue ml-2">Ausfahrt</span>
			</div>
		</div>
	);
};

export default LeftMenu;
