import React from 'react';
import { landingProdUrl } from '@utils/consts';

const AuthMenu = () => {
	return (
		<ul className="flex flex-row items-center px-4">
			<li>
				<a
					className="mr-7 text-contrast-color opacity-50 text-center hover:opacity-100 transition-opacity duration-300 ease-in-out"
					href={`${landingProdUrl}?id=kunden`}
				>
					Für Kunden
				</a>
			</li>
			<li>
				<a
					className="mr-7 text-contrast-color opacity-50 text-center hover:opacity-100 transition-opacity duration-300 ease-in-out"
					href={`${landingProdUrl}?id=aktuelles`}
				>
					Aktuelles
				</a>
			</li>
			<li>
				<a
					className="text-contrast-color opacity-50 text-center hover:opacity-100 transition-opacity duration-300 ease-in-out"
					href={`${landingProdUrl}?id=mitarbeiter`}
				>
					Für Mitarbeiter
				</a>
			</li>
		</ul>
	);
};
export default AuthMenu;
