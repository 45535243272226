import { rootSplitApi } from '@api/rootSplitApi';
import { ResponseConstructInterface } from '@api/types';
import { EditorContentProps } from '@tiptap/react';

export interface NewsItemType {
	content: EditorContentProps;
	newsTitle: string;
}

export interface AdminMessageType {
	bewerbungstext: string;
}

export const homeNewsApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getHomeData: builder.query<ResponseConstructInterface<NewsItemType[]>, any>({
			query: () => ({
				url: 'home/GetHomePageData?deviceType=2',
				method: 'GET',
			}),
		}),
		getContactData: builder.query<any, void>({
			query: () => ({
				url: 'Home/GetContactPageData',
				method: 'GET',
			}),
		}),
		getAllNews: builder.query<any, string>({
			query: id => ({
				url: `DynamicInfoContentApi?userId=${id}`,
				method: 'GET',
			}),
		}),
		sendAdminMessage: builder.mutation<void, AdminMessageType>({
			query: data => ({
				url: `KontakteBewerbungen/Send`,
				method: 'POST',
				data,
			}),
		}),
	}),
});

export const { useGetHomeDataQuery, useSendAdminMessageMutation, useGetContactDataQuery, useGetAllNewsQuery } =
	homeNewsApi;
