import { rootSplitApi } from '@api/rootSplitApi';
import { ResponseInterface } from '@api/types';

export type NotificationTypes = 'found' | 'finding' | 'notReplace';

export interface TermineInterface {
	aAnschrift: string | null;
	bis: string;
	ersatzFinden: string;
	id: number;
	kAnschrift: string | null;
	monat: string;
	terminVonBis: string;
	von: string;
}

export type RoleDataType = {
	mobil: string;
	vorname: string;
	zuname: string;
};

export interface NotitificationItem {
	assistent: RoleDataType;
	id: number;
	klient: RoleDataType;
	termine: TermineInterface;
	text: string | null;
	type: NotificationTypes;
}

interface NotitificationResponseInterface extends ResponseInterface {
	data: NotitificationItem[];
}

export const notificationsApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getAllNotifications: builder.query<NotitificationResponseInterface, void>({
			query: () => ({
				url: 'BenutzerTerminEinladungen/get',
				method: 'GET',
			}),
			providesTags: ['Notifications'],
		}),
		refuseSentence: builder.mutation<any, number>({
			query: id => ({
				url: `BenutzerTerminEinladungen/Decline/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['Notifications'],
		}),
	}),
});

export const { useGetAllNotificationsQuery, useRefuseSentenceMutation } = notificationsApi;
