import * as React from 'react';

function AddressIcon({ ...props }) {
	return (
		<svg fill="none" height={24} width={25} xmlns="http://www.w3.org/2000/svg" {...props}>
			<g
				clipPath="url(#prefix__clip0_3184:638)"
				stroke="#2394AB"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			>
				<path d="M1.5 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4zM16.5 9v9M8.5 6v9" />
			</g>
			<defs>
				<clipPath id="prefix__clip0_3184:638">
					<path d="M0 0h24v24H0z" fill="#fff" transform="translate(.5)" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default AddressIcon;
