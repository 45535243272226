import React from 'react';
import styles from './Radio.module.scss';

interface RadioBtnInterface {
	checked: boolean;
	setChecked: (value: string) => void;
	value?: string;
	name: string;
	label?: string;
}

const RadioBtn = ({ checked = false, setChecked, value, name, label }: RadioBtnInterface) => {
	return (
		<label className={styles.radio}>
			<input checked={checked} name={name} type="radio" value={value} onChange={e => setChecked(e.target.value)} />
			{label && <span className="text-contrast-color">{label}</span>}
		</label>
	);
};
export default RadioBtn;
