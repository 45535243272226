import RS, { StylesConfig } from 'react-select';
import React, { useCallback, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { useOutside } from '@hooks/useOutside';
import { MyOptionType, SelectInterface } from '@components/Form/Select/types';
import { ArrowIcon, CrossIcon, FolderIcon } from '@assets/icons';
import Loader from '@components/Loader';
import styles from './Select.module.scss';

const icons = {
	folder: FolderIcon,
};

const Select = ({
	options,
	value,
	error,
	placeholder = 'Select...',
	isSearchable = false,
	baseLabel,
	customLabel,
	icon,
	multiValues,
	className,
	containerClassName,
	onChange,
	position,
	loading,
	removeMultiValue,
	...rest
}: SelectInterface) => {
	const [visibleSelect, setVisibleSelect] = useState(false);
	const wrapperRef = useRef(null);
	useOutside(wrapperRef, setVisibleSelect);

	const handleChange = (item: MyOptionType) => {
		onChange(item);
		setVisibleSelect(false);
	};

	const Icon = icon ? icons[icon] : null;

	const formatOptionLabel = ({ label }: MyOptionType) => {
		return (
			<div className="flex flex-row">
				<div className="text-contrast-color ml-2">{label}</div>
			</div>
		);
	};

	const selectStyles: StylesConfig<MyOptionType, false> = {
		control: (styleProps, option) => {
			return {
				...styleProps,
				display: option.selectProps?.isSearchable ? 'flex' : 'none',
				margin: 8,
				borderColor: 'var(--colors-bright-gray)',
				'&:hover': { borderColor: 'var(--colors-bright-gray)' },
				boxShadow: 'none',
			};
		},
		option: styleProps => ({
			...styleProps,
			cursor: 'pointer',
		}),
		menu: () => ({
			marginLeft: 8,
			marginRight: 8,
		}),
		container: () => ({ width: '100%' }),
		indicatorsContainer: () => ({
			display: 'none',
		}),
	};

	const viewLabel = useCallback(() => {
		if (multiValues?.length && removeMultiValue) {
			return multiValues.length ? (
				multiValues.map(item => (
					<div className="flex flex-row items-center" key={item.value}>
						<div
							className="px-2 cursor-pointer transition duration-150 hover:opacity-70"
							onClick={() => removeMultiValue(item.value)}
						>
							<CrossIcon height={20} stroke="var(--colors-secondary-red)" width={20} />
						</div>
						<span>{item.label}</span>
					</div>
				))
			) : (
				<span>{placeholder}</span>
			);
		}
		return <span className="text-contrast-color ml-2">{value?.label || placeholder}</span>;
	}, [multiValues, value]);

	return (
		<div className={clsx('flex flex-row', containerClassName)}>
			<div className="relative w-full">
				{baseLabel && (
					<p className="font-roboto-medium ml-4 mb-2">
						<span style={{ color: '#9B9DAE' }}>{baseLabel}</span>
					</p>
				)}
				{customLabel && customLabel}
				<div
					className={clsx(
						className,
						'px-3 h-12 rounded-md cursor-pointer border transition duration-75 border-solid flex flex-row items-center justify-between relative z-20 hover:border-light-green',
						visibleSelect ? 'border-primary-blue pointer-events-none' : 'border-light-dew',
					)}
					onClick={() => setVisibleSelect(!visibleSelect)}
				>
					{loading ? (
						<Loader height={15} width={15} />
					) : (
						<>
							<div className="flex flex-row items-center">
								{Icon && <Icon />}
								{/* @ts-ignore */}
								{viewLabel()}
							</div>
							<ArrowIcon className="transform -rotate-90" height={12} stroke="var(--colors-primary-blue)" width={20} />
						</>
					)}
				</div>
				{error && (
					<p className="mb-2 text-secondary-red ml-2 text-sm">
						<span>{error}</span>
					</p>
				)}
				<AnimatePresence>
					{visibleSelect && (
						<motion.div
							animate={{
								opacity: 1,
								transition: {
									type: 'easyInOut',
									stiffness: 300,
								},
							}}
							className={clsx(
								'flex mt-1 pt-2 z-10 justify-center items-center bg-white absolute w-full rounded-md',
								position && styles[position],
							)}
							exit={{
								opacity: 0,
								transition: { duration: 0.3 },
							}}
							initial={{
								opacity: 0,
							}}
							ref={wrapperRef}
							style={{
								boxShadow: `0px 8px 28px -6px rgba(11, 17, 33, 0.12), 0px 18px 88px -4px rgba(11, 17, 33, 0.04)`,
							}}
						>
							<RS
								autoFocus
								backspaceRemovesValue={false}
								components={{ IndicatorSeparator: null }}
								controlShouldRenderValue={false}
								// @ts-ignore
								formatOptionLabel={formatOptionLabel}
								hideSelectedOptions={false}
								isClearable={false}
								isSearchable={isSearchable}
								menuIsOpen
								options={options}
								placeholder="Search..."
								// @ts-ignore
								styles={selectStyles}
								tabSelectsValue={false}
								theme={theme => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										text: 'var(--colors-dark-charcoal)',
										primary25: 'var(--colors-light-dew)',
										primary: 'var(--colors-light-dew)',
										primary50: 'var(--colors-light-dew)',
									},
								})}
								// @ts-ignore
								onChange={handleChange}
								{...rest}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default Select;
