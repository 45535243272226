import React from 'react';

function LockIcon({ stroke = '#2394AB', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17 10V7A5 5 0 007 7v3H5a2 2 0 00-2 2c0 13.792 18 13.792 18 0a2 2 0 00-2-2h-9"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default LockIcon;
