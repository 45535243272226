import { scroller } from 'react-scroll/modules';
import { headerLinkScrollIdType } from '@utils/types';
import moment from 'moment';
import { FormikErrors, FormikValues } from 'formik/dist/types';

export const scrollByID = (scrollId: headerLinkScrollIdType, offset = 50) => {
	scroller.scrollTo(scrollId, {
		duration: 300,
		delay: 0,
		smooth: 'easeInOut',
		offset,
	});
};

export const showInMapClicked = (lat: string, lng: string) => {
	window.open(
		`https://www.google.com/maps/place/${parseInt(lat, 10)}°07'24.0%22N+${parseInt(
			lng,
			10,
		)}°07'23.4%22E/@${lat},${lng}/`,
	);
};

export const viewExistData = (check: boolean, data?: string | null) => {
	if (check && data) return data;
	return '-';
};

export const viewNotLater = (date: string, format: string) => {
	return moment(date, format) > moment();
};

export const formatTime = (time: moment.MomentInput, format?: moment.MomentFormatSpecification, result?: string) => {
	return moment(time, format).format(result?.length ? result : 'HH:mm');
};

export const setFieldForm = (
	method: (field: string, value: any, shouldValidate?: boolean) => void,
	setErrors: (errors: FormikErrors<FormikValues>) => void,
	nameField: string,
	val: any,
) => {
	method(nameField, val, false);
	setErrors({});
};
