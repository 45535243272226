import React from 'react';

function TumblrIcon({ fill = '#fff', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#prefix__clip0)">
				<path
					d="M18.895 22.517c-.798.867-2.646 1.456-4.301 1.483h-.182c-5.557 0-6.766-4.164-6.766-6.594v-6.748H5.458a.452.452 0 01-.324-.137.472.472 0 01-.134-.33V7.003a.81.81 0 01.142-.458.782.782 0 01.376-.29C8.373 5.23 9.266 2.693 9.388.765 9.423.25 9.685 0 10.126 0H13.4a.451.451 0 01.33.134.468.468 0 01.137.333V5.87h3.823c.121 0 .238.05.324.137a.472.472 0 01.134.33v3.83a.472.472 0 01-.282.432.454.454 0 01-.176.036h-3.84v6.245c0 1.568 1.015 2.001 1.64 2.001a4.537 4.537 0 001.488-.32.973.973 0 01.595-.107.482.482 0 01.34.37l1.012 3.014c.068.237.14.498-.03.68z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path d="M0 0h24v24H0z" fill="#fff" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default TumblrIcon;
