import Root from '@pages/Root';
import { ContactIcon, HomeIcon } from '@assets/icons/menu';

export const ROOT_ROUTES = [
	{
		name: 'home',
		path: '/',
		exact: true,
		Icon: HomeIcon,
		component: Root.Home,
	},
	{
		name: 'kontakte',
		path: '/kontakte',
		exact: true,
		Icon: ContactIcon,
		component: Root.Contacts,
	},
	{
		name: 'profile',
		path: '/profile',
		exact: true,
		component: Root.Profile,
	},
];
