import { GetNewsType } from '@landing/api/landing/types';
import { rootSplitApiLanding } from '@landing/api/rootSplitApiLanding';

export const landingApi = rootSplitApiLanding.injectEndpoints({
	endpoints: builder => ({
		getNews: builder.query<any, GetNewsType>({
			query: ({ perPage, page }) => ({
				url: `dynamic_info_content/get_news_landing`,
				method: 'GET',
				params: {
					perPage,
					page,
				},
			}),
			providesTags: ['News'],
		}),
	}),
});

export const { useGetNewsQuery } = landingApi;
