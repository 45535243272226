import { createSelector, createSlice } from '@reduxjs/toolkit';
import { UserInterface } from '@api/user';
import type { RootState } from '../index';

type AuthState = {
	user: UserInterface;
};

const initialState = {
	code: null,
	email: null,
	firstName: null,
	lastName: null,
	roles: [],
	token: null,
	isAuth: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState: { user: initialState } as AuthState,
	reducers: {
		setCredentials: (state, action) => {
			state.user = { ...state.user, ...action.payload };
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		signOut: state => {},
	},
});

export const { setCredentials, signOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectUserRole = createSelector([selectCurrentUser], user =>
	user.roles && user.roles.length ? user.roles[0] : null,
);
export const selectIsAuth = createSelector([selectCurrentUser], user => user.isAuth);
