import * as React from 'react';

function HomeIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M15 12H9v10H5a2 2 0 01-2-2V9.978a2 2 0 01.772-1.58l7-5.444a2 2 0 012.456 0l7 5.445A2 2 0 0121 9.978V20a2 2 0 01-2 2h-4v-7"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default HomeIcon;
