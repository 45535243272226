import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import { rootSplitApi } from '@api/rootSplitApi';
import authReducer from './auth';
import clientReducer from './client';
import mainReducer from './main';
import adminReducer from './admin';

const rootConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [rootSplitApi.reducerPath],
};

const combinedReducer = combineReducers({
	[rootSplitApi.reducerPath]: rootSplitApi.reducer,
	auth: persistReducer(rootConfig, authReducer),
	client: persistReducer(rootConfig, clientReducer),
	main: persistReducer(rootConfig, mainReducer),
	admin: persistReducer(rootConfig, adminReducer),
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'auth/signOut') {
		// eslint-disable-next-line no-param-reassign
		state = {} as RootState;
		storage.removeItem('persist:root');
		window.location.reload();
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		[
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}),
			rootSplitApi.middleware,
		].flat(),
});

export const persist = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
