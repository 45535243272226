import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useDeferredRenderEffect(fn: EffectCallback, inputs?: DependencyList) {
	const didMountRef = useRef<string | null>(null);

	useEffect(() => {
		if (didMountRef.current && didMountRef.current !== JSON.stringify(inputs)) {
			didMountRef.current = JSON.stringify(inputs);

			return fn();
		}
		didMountRef.current = JSON.stringify(inputs);
		return () => {};
	}, [fn, inputs]);
}
