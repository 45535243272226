import React from 'react';
import { renderRoutes } from 'react-router-config';
import { LeftMenu } from '@components/index';
import { Header } from '@components/Dashboard';
import styles from './RootLayout.module.scss';

const RootLayout = ({ route }: any) => {
	return (
		<div className="flex flex-row min-h-screen bg-light-gray max-h-screen items-stretch h-screen">
			<LeftMenu routes={route.routes} />
			<div className="px-12 py-6 flex-1 h-full flex flex-col overflow-hidden">
				<Header />
				<div className={styles.root}>{renderRoutes(route.routes)}</div>
			</div>
		</div>
	);
};

export default RootLayout;
