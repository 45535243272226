import * as React from 'react';

function FaceBook({ fill = '#fff', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 10 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.112 3.295h1.811V.14A23.394 23.394 0 007.283 0c-2.611 0-4.4 1.643-4.4 4.663v2.779H0v3.527h2.882v8.876h3.535V10.97h2.765l.44-3.527H6.415v-2.43c0-1.02.275-1.718 1.696-1.718z"
				fill={fill}
			/>
		</svg>
	);
}

export default FaceBook;
