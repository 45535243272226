import * as React from 'react';

function TopLeft({ ...props }) {
	return (
		<svg fill="none" viewBox="0 0 154 408" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				clipRule="evenodd"
				d="M-23.611-3.196C22.126-15.293 74.757-47.42 113.74-20.605c38.794 26.684 25.166 87.458 32.198 134.032 5.712 37.824 11.98 74.246 4.929 111.843-7.995 42.633-17.921 86.241-47.944 117.533-33.585 35.004-78.547 71.329-126.535 64.322-47.818-6.982-65.367-66.521-102.276-97.73-32.874-27.795-81.222-36.623-101.527-74.592-22.009-41.155-34.259-95.585-11.169-136.142 22.848-40.133 80.157-38.549 121.877-58.317C-84.97 25.308-57.559 5.782-23.611-3.196z"
				fill="#87D0CE"
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default TopLeft;
