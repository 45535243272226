import * as React from 'react';

function HonorareIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M2 7a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V7z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
			<path
				d="M12 15a3 3 0 100-6 3 3 0 000 6zM2 9a4 4 0 004-4M18 19a4 4 0 014-4"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default HonorareIcon;
