import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { getRoutes } from '@router/index';
import { renderRoutes } from 'react-router-config';
import { selectIsAuth, selectUserRole } from '@store/auth';
import { useSelector } from 'react-redux';
import 'moment/locale/de';
import moment from 'moment';

moment().locale('he');

const App = () => {
	const isAuth = useSelector(selectIsAuth);
	const role = useSelector(selectUserRole);

	return (
		<BrowserRouter>
			<Route
				exact
				path="/"
				render={() => {
					return isAuth ? <Redirect to="/" /> : <Redirect to="/login" />;
				}}
			/>
			{renderRoutes(getRoutes(role))}
		</BrowserRouter>
	);
};

export default App;
