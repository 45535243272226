import { TermineCreateInitialValues } from '@components/Erstellen/Erstellen';
import { rootSplitApi } from '@api/rootSplitApi';
import { GetTermineParams } from '@api/types';

export const clientApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getClientTermine: builder.query<any, GetTermineParams>({
			query: ({ month, status }) => ({
				url: `TermineK/Get?Monat=${month}${status || status === '' ? `&status=${status}` : ''}`,
				method: 'GET',
			}),
			providesTags: ['TermineClient'],
		}),
		getClientTerminerfassung: builder.query<any, void>({
			query: () => ({
				url: 'TermineK/Terminefassung',
				method: 'GET',
			}),
			providesTags: ['Terminerfassung'],
		}),
		createTerminerfassung: builder.mutation<TermineCreateInitialValues, any>({
			query: data => ({
				url: 'TermineK/CreateTerminefassung',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['Terminerfassung'],
		}),
		setTimeLog: builder.mutation<any, any>({
			query: data => ({
				url: 'TermineK/geht',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['TermineClient'],
		}),
		setDidNotCome: builder.mutation<any, any>({
			query: data => ({
				url: 'TermineK/nicht_gekommen',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['TermineClient'],
		}),
		protestClientTime: builder.mutation({
			query: data => ({
				url: `TermineK/change_klient_time`,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['TermineClient'],
		}),
		getHonorare: builder.query<any, void>({
			query: () => ({
				url: 'HonorarNoten/GetHonorarData',
				method: 'GET',
			}),
			providesTags: ['Honorare'],
		}),
	}),
});

export const {
	useGetClientTermineQuery,
	useGetClientTerminerfassungQuery,
	useCreateTerminerfassungMutation,
	useSetTimeLogMutation,
	useSetDidNotComeMutation,
	useProtestClientTimeMutation,
	useGetHonorareQuery,
} = clientApi;
