import React, {
	ButtonHTMLAttributes,
	ReactElement,
	TextareaHTMLAttributes,
	InputHTMLAttributes,
	useState,
} from 'react';
import clsx from 'clsx';
import { InputVariantEnum } from '@components/Form/TextInput/types';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimationError } from '@components/Animation';
import styles from './TextInput.module.scss';

interface DefaultTextInputInterface {
	variant?: string;
	rightIcon?: (props: ButtonHTMLAttributes<HTMLButtonElement>) => ReactElement;
	leftIcon?: (props: ButtonHTMLAttributes<HTMLButtonElement>) => ReactElement;
	error?: string;
	label?: string;
	className?: string;
	placeholder?: string;
	type?: string;
	multiline?: boolean;
	withoutAutoCompleteShadow?: boolean;
	defaultLabel?: string;
}

interface TextInputAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, DefaultTextInputInterface {
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement> | any) => void;
}

// @ts-ignore
interface TextInputProps extends InputHTMLAttributes<HTMLInputElement>, DefaultTextInputInterface {
	onChange?: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
}

interface DynamicComponent {
	multiline?: boolean;
}

const Component: React.FC<TextInputProps | TextInputAreaProps> = ({ multiline, ...props }: DynamicComponent) =>
	multiline ? <textarea {...props} /> : <input {...props} />;

const TextInput: React.FC<TextInputProps | TextInputAreaProps> = ({
	className,
	rightIcon,
	variant = InputVariantEnum.default,
	error,
	label,
	leftIcon,
	multiline = false,
	withoutAutoCompleteShadow = true,
	defaultLabel,
	type,
	...props
}: TextInputProps | TextInputAreaProps) => {
	const [visiblePwd, setVisiblePwd] = useState(false);

	const wrapperClassName = clsx(
		'relative px-2 flex items-stretch justify-between relative',
		className,
		defaultLabel,
		error && 'shadow-redShadow',
		// eslint-disable-next-line no-nested-ternary
		withoutAutoCompleteShadow && styles['without-autofill'],
	);

	const inputClassName = clsx(styles[variant]);

	const viewRightIcon = () => {
		if (type === 'password') {
			return (
				<div
					className="min-h-full flex items-center px-2 order-3 cursor-pointer"
					onClick={() => setVisiblePwd(!visiblePwd)}
				>
					{rightIcon ? rightIcon({}) : <></>}
				</div>
			);
		}
		return <div className="min-h-full flex items-center px-2 order-3">{rightIcon ? rightIcon({}) : <></>}</div>;
	};

	return (
		<>
			{label && <p className="mb-2 text-contrast-color">{label}</p>}
			{defaultLabel && <p className="mb-2 text-silver-sand ml-2 text-sm">{defaultLabel}</p>}
			<div className={clsx(wrapperClassName)}>
				{rightIcon ? viewRightIcon() : null}
				<Component
					multiline={multiline}
					type={type === 'password' && !visiblePwd ? 'password' : 'text'}
					{...props}
					className={clsx(inputClassName, 'order-2')}
				/>
				{leftIcon ? (
					<div className="min-h-full flex items-center px-2 order-1 py-1.5 self-start">{leftIcon({})}</div>
				) : null}
				{/* {error && <span className={clsx(styles.error, 'text-xs text-secondary-red')}>{error}</span>} */}
			</div>
			<AnimationError error={error} />
		</>
	);
};

export default TextInput;
