import React, { useEffect, useState } from 'react';
import moment from 'moment';

interface CustomTimerInterface {
	dateStartTime: string;
	getDataTimer: (p: string) => void;
	activate?: boolean;
	className?: string;
}

const formatNumber = (number: number) => `0${number}`.slice(-2);

const getRemaining = (time: number) => {
	const hours = Math.floor(time / 3600);
	const mins = Math.floor((time % 3600) / 60);
	const secs = Math.floor((time % 3600) % 60);
	return { mins: formatNumber(mins), secs: formatNumber(secs), hours: formatNumber(hours) };
};

const Timer = ({ dateStartTime, getDataTimer, activate, className }: CustomTimerInterface) => {
	const startTime = moment().unix() - moment(dateStartTime).unix();

	const [remainingSecs, setRemainingSecs] = useState(startTime || 0);

	const { hours, mins, secs } = getRemaining(remainingSecs);
	const [isActive, setIsActive] = useState(activate || true);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		activate && setIsActive(activate);
	}, [activate]);

	useEffect(() => {
		getDataTimer(`${hours}:${mins}:${secs}`);
	}, [getDataTimer, hours, mins, secs]);

	useEffect(() => {
		let interval: number | NodeJS.Timeout | null | undefined = null;
		if (isActive) {
			interval = setInterval(() => {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				setRemainingSecs(remainingSecs => remainingSecs + 1);
			}, 1000);
		} else if (!isActive && remainingSecs !== 0) {
			// @ts-ignore
			clearInterval(interval);
		}
		return () => clearInterval(interval as NodeJS.Timeout);
	}, [isActive, remainingSecs]);

	return (
		<>
			<span {...{ className }}>{`${hours} : ${mins}`}</span>
		</>
	);
};
export default Timer;
