import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { headerLinks } from '@utils/consts';
import { scrollByID } from '@utils/helpers';
import { headerLinkInterface } from '@utils/types';
import { Link } from 'react-scroll';
import clsx from 'clsx';
import styles from './MenuItems.module.scss';

export enum MenuItemsEnum {
	default = 'flex flex-row items-center w-8/12',
	footer = 'flex-col flex',
}

interface MenuItemsInterface {
	type: MenuItemsEnum;
	className?: string;
}

const MenuItems = ({ type, className }: MenuItemsInterface) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get('id');

	const scrollToElement = useCallback(
		async (scrollId, offset?) => {
			if (pathname !== '/') history.push('/');
			await setTimeout(() => {
				scrollByID(scrollId, offset);
			}, 200);
		},
		[history, pathname],
	);

	useEffect(() => {
		const scrollEl = headerLinks.find((item: headerLinkInterface) => item.scrollId === id);
		if (id && scrollEl) {
			(async () => {
				await scrollToElement(scrollEl.scrollId, scrollEl.offset);
			})();
		}
	}, [id, scrollToElement]);

	return (
		<div className={type}>
			{headerLinks.map(({ title, scrollId, offset }: headerLinkInterface, index) => {
				if (pathname === '/') {
					return (
						<Link
							activeClass={styles.active}
							className={clsx(styles.menuItem, type === MenuItemsEnum.footer && 'mb-4 font-light', `cursor-pointer flex-auto`)}
							delay={0}
							duration={300}
							key={index}
							offset={offset}
							smooth="easeInOut"
							spy
							to={scrollId}
							onClick={() => scrollToElement(scrollId, offset)}
						>
							<span className={clsx(className, type === MenuItemsEnum.footer ? 'text-white' : 'text-white-opacity', 'transition duration-300 easy-in-out hover:opacity-70')}>
								{title}
							</span>
						</Link>
					);
				}
				return (
					<span
						className={clsx(styles.menuItem, type === MenuItemsEnum.footer && 'mb-4 font-light', `cursor-pointer flex-auto`)}
						key={index}
						onClick={() => scrollToElement(scrollId, offset)}
					>
						<span className={clsx(className,  type === MenuItemsEnum.footer ? 'text-white' : 'text-white-opacity', 'transition duration-300 easy-in-out hover:opacity-70')}>
							{title}
						</span>
					</span>
				);
			})}
		</div>
	);
};
export default MenuItems;
