import * as React from 'react';

const AddIcon = ({ stroke = '#fff', ...props }) => {
	return (
		<svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M7 1.667v10.666M1.667 7h10.666"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.5}
			/>
		</svg>
	);
};

export default AddIcon;
