import { BerichtIcon, ClockIcon, DocumentationIcon, PencilIcon, TerminerfassungIcon } from '@assets/icons/menu';
import Assistant from '@pages/Assistant';
import Root from '@pages/Root';

export const ASSISTANT_ROUTES = [
	{
		name: 'termine',
		path: '/termine',
		Icon: ClockIcon,
		exact: true,
		component: Assistant.Termine,
	},
	{
		name: 'termine-wahlen',
		path: '/termine-wahlen',
		exact: true,
		Icon: TerminerfassungIcon,
		component: Assistant.TermineWahlen,
	},
	{
		name: 'sperzeiten',
		path: '/sperzeiten',
		Icon: PencilIcon,
		exact: true,
		component: Assistant.Sperzeiten,
	},
	{
		name: 'dokumentation',
		path: '/dokumentation',
		exact: true,
		Icon: DocumentationIcon,
		component: Root.Documentation,
	},
	{
		name: 'bericht',
		path: '/bericht',
		Icon: BerichtIcon,
		exact: true,
		component: Assistant.Bericht,
	},
];
