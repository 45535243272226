import React, { useContext } from 'react';
import { ModalContext } from '@contexts/ModalContext';
import { Button, CustomModal } from '@components';

export const Modal = () => {
	const { modalContent, handleModal, modal } = useContext(ModalContext);
	if (modal) {
		return (
			<CustomModal setVisible={() => handleModal()} visible={modal}>
				<span>{modalContent}</span>
				<div className="flex flex-row">
					{/* // @ts-ignore */}
					<Button onClick={() => handleModal()}>yes</Button>
					<Button onClick={() => handleModal()}>no</Button>
				</div>
			</CustomModal>
		);
	}
	return <></>;
};
