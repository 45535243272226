import React from 'react';
import { useGetHomeDataQuery } from '@api';
import Loader from '@components/Loader';
import { EditorContent, useEditor } from '@tiptap/react';
import { Paragraph } from '@tiptap/extension-paragraph';
import StarterKit from '@tiptap/starter-kit';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import clsx from 'clsx';
import styles from '@components/TipTapEditor/TipTapEditor.module.scss';
import { NoData } from '@components';
import { FileLink } from '@components/Links';

interface ViewEditorDataProps {
	data: any;
	files: string[];
}

const ViewEditorData = ({ data, files }: ViewEditorDataProps) => {
	const editor = useEditor({
		extensions: [StarterKit, OrderedList, BulletList, ListItem, Document, Paragraph, Text],
		editorProps: {
			editable: () => false,
			attributes: {
				class: clsx(styles.ProseMirror, 'p-5'),
			},
		},
		content: data,
	});
	return (
		<div>
			<div className="mb-2">
				<EditorContent editor={editor} />
			</div>
			<div className="mb-2">
				{files.map((file, i) => (
					<FileLink href={file} key={i} name={`${file.split('/').pop()}`} />
				))}
			</div>
		</div>
	);
};

const NewsTable = () => {
	// @ts-ignore
	const { data, isLoading } = useGetHomeDataQuery();

	return (
		<div className="overflow-scroll">
			{isLoading ? (
				<Loader className="flex-grow flex justify-center" height={50} width={50} />
			) : (
				<>
					{data && data.data.length ? (
						data.data.map((item, index) => {
							return (
								<div className={data.data.length > index + 1 ? 'mb-8' : ''} key={index}>
									<div className="mb-4">
										<span className="text-xl font-medium text-contrast-color">{item?.newsTitle}</span>
									</div>
									<div>
										{item.content &&
											// @ts-ignore
											// eslint-disable-next-line @typescript-eslint/no-shadow
											item.content.map((item, index) => {
												if (item?.editorData) {
													return <ViewEditorData data={item?.editorData} files={item.files} key={index} />;
												}
												return <></>;
											})}
									</div>
								</div>
							);
						})
					) : (
						<div className="flex flex-1 justify-center">
							<NoData title="Nachrichten" />
						</div>
					)}
				</>
			)}
		</div>
	);
};
export default NewsTable;
