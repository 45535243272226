import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface AnimationErrorProps {
	error?: string;
}

export const AnimationError: React.FC<AnimationErrorProps> = ({ error }) => {
	return (
		<AnimatePresence initial={false}>
			{error && (
				<motion.div
					animate={{ opacity: 1, height: 'auto' }}
					exit={{ opacity: 0, height: 0 }}
					initial={{ opacity: 0, height: 0 }}
				>
					<span className="text-xs text-secondary-red">{error}</span>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
