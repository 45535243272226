import Auth from '@pages/Auth';
import RedirectPage from '@pages/Redirect';

export const AUTH_ROUTES = [
	{
		name: 'login',
		path: '/login',
		exact: true,
		Icon: null,
		component: Auth.Login,
	},
	{
		name: 'register',
		path: '/register',
		exact: true,
		Icon: null,
		component: Auth.SignUp,
	},
	{
		name: 'not-found',
		path: '*',
		Icon: null,
		exact: true,
		component: RedirectPage,
	},
];
