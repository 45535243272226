import React from 'react';

function AccountIcon({ stroke = '#2394AB', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 11a4 4 0 100-8 4 4 0 000 8zM16.5 21.142c1.389-.25 2.49-.63 3-1.142.073-.646 0-1.666 0-1.666 0-.884-.42-1.732-1.17-2.357-2.37-1.974-10.289-1.974-12.658 0-.75.625-1.171 1.473-1.171 2.357 0 0-.072 1.02 0 1.666.511.512 1.612.893 3 1.142"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default AccountIcon;
