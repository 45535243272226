import React from 'react';

interface PerfectScrollBarInterface {
	children: React.ReactNode;
	className?: string;
}

const PerfectScrollbar = ({ children, className }: PerfectScrollBarInterface) => {
	return <div className={`h-full overflow-y-scroll ${className || ''}`}>{children}</div>;
};

export default PerfectScrollbar;
