import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { baseAppHeaders, baseAppUrl } from '@config/api';
import { RootState } from '@store/index';
import { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes';

interface AxiosBaseQueryInterface {
	baseUrl: string;
	prepareHeaders?: () => AxiosRequestConfig['headers'];
}

export function axiosBaseQuery({ baseUrl, prepareHeaders = () => ({}) }: AxiosBaseQueryInterface): BaseQueryFn<
	{
		url: string;
		method: AxiosRequestConfig['method'];
		data?: AxiosRequestConfig['data'];
		params?: AxiosRequestConfig['params'];
	},
	unknown,
	unknown
> {
	return async ({ url, method, data, params }, { getState }) => {
		let headers: any = {};

		const authHeaders = () => {
			const token = (getState() as RootState).auth?.user.token;
			if (token) {
				headers.authorization = `Bearer ${token}`;
			}
			return headers;
		};

		headers = {
			...headers,
			...authHeaders(),
			...baseAppHeaders,
			...prepareHeaders(),
		};

		try {
			const result = await axios({
				baseURL: baseAppUrl,
				url: baseUrl + url,
				method,
				data,
				params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: { status: err.response?.status, data: err.response?.data },
			};
		}
	};
}
