import React from 'react';

interface DefaultLinkInterface {
	href: string;
	children: React.ReactNode;
	className?: string;
}

const DefaultLink = ({ href, children, className = 'text-primary-blue' }: DefaultLinkInterface) => {
	return (
		<a className={`transition duration-300 easy-in-out hover:opacity-70 ${className}`} href={href}>
			{children}
		</a>
	);
};

export default DefaultLink;
