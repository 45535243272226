import * as React from 'react';

function DocumentationIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M16 17H8M16 13H8M10 9H8M10 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2v-8M13 2l7 7h-7V2z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default DocumentationIcon;
