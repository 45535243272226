import * as React from 'react';

function ContactIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#prefix__clip0)" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
				<path d="M16 3.13a4 4 0 010 7.75M9 11a4 4 0 100-8 4 4 0 000 8zM13 21.142c1.389-.25 2.49-.63 3-1.142.073-.646 0-1.666 0-1.666 0-.884-.42-1.732-1.17-2.357-2.37-1.974-10.289-1.974-12.658 0-.75.625-1.171 1.473-1.171 2.357 0 0-.072 1.02 0 1.666.511.512 1.612.893 3 1.142M20 21.141c1.388-.25 2.488-.63 3-1.142.072-.647 0-1.667 0-1.667 0-.884-.421-1.732-1.172-2.357-.44-.367-1.074-.666-1.828-.897" />
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path d="M0 0h24v24H0z" fill="#fff" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default ContactIcon;
