import React from 'react';
import { Button } from '@components/index';
import { ButtonVariantEnum } from '@components/Button/types';
import { selectStartTime, setStartTime } from '@store/client';
import { useDispatch, useSelector } from 'react-redux';
import { useSetDidNotComeMutation, useSetTimeLogMutation } from '@api';
import moment from 'moment';

const ClientTracker = ({ id, aCode }: { id: number; aCode: string }) => {
	const dispatch = useDispatch();
	const [setTimerLogs] = useSetTimeLogMutation();
	const [setDidNotCome] = useSetDidNotComeMutation();
	const trackerSelect = useSelector(selectStartTime);

	const currentDate = moment().format('YYYY.MM.DD');
	const currentTime = moment().format('HH:mm');
	const disabledButtons = !!trackerSelect.von && id === trackerSelect.id;

	return (
		<>
			<Button
				className={`bg-light-gray rounded-lg py-1 px-4 w-full ${disabledButtons ? 'pointer-events-none' : ''}`}
				variant={disabledButtons ? ButtonVariantEnum.default : ButtonVariantEnum.flat}
				onClick={() =>
					dispatch(
						setStartTime({
							id,
							von: `${currentDate} ${currentTime}`,
						}),
					)
				}
			>
				<span className="text-lg">KOMMIT</span>
			</Button>
			<Button
				className="bg-light-gray rounded-lg py-1 px-4 w-full"
				variant={ButtonVariantEnum.flat}
				onClick={() =>
					setTimerLogs({
						...trackerSelect,
						bis: `${currentDate} ${currentTime}`,
						aCode,
					})
				}
			>
				<span className="text-lg">GEHT</span>
			</Button>
			<Button
				className="border border-solid border-2 py-1 px-4 rounded-lg w-full"
				disabled={disabledButtons}
				variant={ButtonVariantEnum.secondary}
				onClick={() => setDidNotCome({ id })}
			>
				<span className="text-lg">NIGHT GEKOMMIT</span>
			</Button>
		</>
	);
};
export default ClientTracker;
