import Client from '@pages/Client';
import { ClockIcon, TerminerfassungIcon, HonorareIcon, DocumentationIcon } from '@assets/icons/menu';
import Root from '@pages/Root';

export const CLIENT_ROUTES = [
	{
		name: 'termine',
		path: '/termine',
		Icon: ClockIcon,
		exact: true,
		component: Client.Termine,
	},
	{
		name: 'terminerfassung',
		path: '/terminerfassung',
		exact: true,
		Icon: TerminerfassungIcon,
		component: Client.Terminerfassung,
	},
	{
		name: 'dokumentation',
		path: '/dokumentation',
		exact: true,
		Icon: DocumentationIcon,
		component: Root.Documentation,
	},
	{
		name: 'honorare',
		path: '/honorare',
		Icon: HonorareIcon,
		exact: true,
		component: Client.Honorare,
	},
];
