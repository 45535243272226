import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserRole } from '@store/auth';
import { USER_ROLES } from '@utils/consts';
import { AccountIcon } from '@assets/icons';
import { viewExistData } from '@utils/helpers';
import { NotificationComponentWithTypeInterface } from '@components/Notifications/Notification.types';
import { Button, Timer } from '@components';
import moment from 'moment';
import { ButtonVariantEnum } from '@components/Button/types';
import { useApproveTermineWahlenMutation, useRefuseSentenceMutation } from '@api';

const NotificationFinding = ({ user, termine, setType, id }: NotificationComponentWithTypeInterface) => {
	const [approveHandler, { isLoading: isLoadingTermineWahlen }] = useApproveTermineWahlenMutation();
	const [refuseSentence, { isLoading: isLoadingRefuseSentence }] = useRefuseSentenceMutation();
	const role = useSelector(selectUserRole);
	const keyWord =
		// eslint-disable-next-line no-nested-ternary
		role === USER_ROLES.ASSISTANT_ROLE
			? 'aAnschrift'
			: role === USER_ROLES.CLIENT_ROLE || role === USER_ROLES.CLIENT_SECOND_ROLE
			? 'kAnschrift'
			: null;

	const changeStatus = (value: string) => {
		if (moment(value, 'HH:MM:SS') > moment('03:00', ['h:m a', 'H:m'])) {
			setType('notReplace');
		}
	};

	return (
		<>
			<h5 className="mb-4 text-contrast-color text-lg">Der Assistent sucht eine Vertretung</h5>
			<div className="flex flex-row items-start">
				<div className="rounded-full bg-primary-blue-green bg-opacity-10 p-2">
					<AccountIcon height={40} width={40} />
				</div>
				<div className="flex flex-col flex-1 ml-4">
					<div className="mb-4 flex flex-row">
						{role === USER_ROLES.ASSISTANT_ROLE ? (
							<span className="text-gray-main">
								Das Angebot wird gültig sein{' '}
								<Timer
									className="text-primary-blue-green"
									dateStartTime={termine.ersatzFinden}
									getDataTimer={value => changeStatus(value)}
								/>
							</span>
						) : (
							<>
								<p className="mr-1 text-primary-blue">
									{viewExistData(!!user.vorname && !!user.zuname, `${user.vorname} ${user.zuname}`)}
								</p>
								<span>sta cercando un sostituto</span>
							</>
						)}
					</div>
					<div className="flex flex-row items-start justify-between mb-4">
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">Date</span>
							<span className="text-contrast-raven">
								{viewExistData(!!termine?.terminVonBis, termine?.terminVonBis.split(' ')[0])}
							</span>
						</div>
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">Geplante Zeit</span>
							<span className="text-contrast-raven">
								{viewExistData(!!(termine?.von && termine?.bis), `${termine?.von} - ${termine?.bis}`)}
							</span>
						</div>
						<div className="flex flex-col">
							<span className="mb-2 text-rainy-gray text-sm">die Anschrift</span>
							<span className="text-contrast-raven">
								{viewExistData(!!keyWord && !!termine[keyWord], termine[keyWord || 'aAnschrift'])}
							</span>
						</div>
					</div>
					<div>
						<Button
							className="py-2 px-6 mr-6 rounded-full"
							loading={isLoadingTermineWahlen}
							text="Wahlen"
							onClick={() => approveHandler(termine.id)}
						/>
						<Button
							className="py-2 px-6 rounded-full"
							loading={isLoadingRefuseSentence}
							text="Ablehnen"
							variant={ButtonVariantEnum.secondary}
							onClick={() => refuseSentence(id)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotificationFinding;
