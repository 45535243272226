import React from 'react';
import LogoIcon from '@assets/icons/LogoIcon';
import clsx from 'clsx';
import { LinkWrapper } from '@components/index';
import { TumblrIcon, TwitterIcon, InstagramIcon, PlusIcon } from '@assets/icons';
import { renderRoutes } from 'react-router-config';
import { BottomLeft, BottomRight, TopLeft, TopLeftRight } from '@assets/icons/home';
import styles from './AuthLayout.module.scss';

const AuthLayout = ({ route }: any) => {
	return (
		<div className="flex w-full flex-row">
			<div className={clsx(styles['auth-present'], 'w-3/6 h-screen flex bg-primary-blue relative')}>
				<div className={styles['auth-home__bottom-left']}>
					<BottomLeft height="100%" width="100%" />
				</div>
				<div className={styles['auth-home__bottom-right']}>
					<BottomRight height="100%" width="100%" />
				</div>
				<div className={styles['auth-home__top-left-right']}>
					<TopLeftRight height="100%" width="100%" />
				</div>
				<div className={styles['auth-home__top-left']}>
					<TopLeft height="100%" width="100%" />
				</div>

				<div className="flex flex-col items-center self-stretch justify-between py-10 flex-1 relative">
					<div className="flex flex-row items-center">
						<LogoIcon height={120} width={120} />
						<h2 className="text-white text-5xl ml-6 font-custom">PAV WIEN</h2>
					</div>
					<div className={clsx('pr-10', styles['auth-present__plus'])}>
						<PlusIcon height="100%" width="100%" />
					</div>
					<h3 className="text-white text-3xl text-center mb-3">
						Persönliche Assistenz
						<br /> ist Freiheit trotz
						<br /> Handicap
					</h3>
					<div className="flex flex-row">
						<LinkWrapper customStyles="mr-4 p-3 bg-light-turquoise" link="https://pav-wien.tumblr.com/">
							<TumblrIcon height={20} width={20} />
						</LinkWrapper>
						<LinkWrapper customStyles="mr-4 p-3 bg-light-turquoise" link="https://twitter.com/PavWien">
							<TwitterIcon height={20} width={20} />
						</LinkWrapper>
						<LinkWrapper customStyles="p-3 bg-light-turquoise" link="https://www.instagram.com/pavpersonalassistance">
							<InstagramIcon height={20} width={20} />
						</LinkWrapper>
					</div>
				</div>
			</div>
			{renderRoutes(route.routes)}
		</div>
	);
};

export default AuthLayout;
