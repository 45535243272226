import { PencilIcon } from '@assets/icons/menu';
import Admin from '@pages/Admin';

export const ADMIN_ROUTES = [
	{
		name: 'Neuigkeiten hinzufügen',
		path: '/neuigkeiten',
		exact: true,
		Icon: PencilIcon,
		component: Admin.NewsHandler,
	},
];
