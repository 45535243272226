import React, { useEffect, useState } from 'react';
import { CheckBox, LinkWrapper } from '@components';
import { AccountIcon, PhoneIcon } from '@assets/icons';
import { VertretungItemInterface } from '@components/Vertretung/Vertretung.types';

interface VertretungItemPropsInterface {
	item: VertretungItemInterface;
	selectItem: (p1: VertretungItemInterface['aCode']) => void;
	check: boolean;
}

const VertretungItem = ({ item, selectItem, check = false }: VertretungItemPropsInterface) => {
	const { zuname, vorname, telNrWeitergabe, mobil } = item;
	const [checked, setChecked] = useState(check);

	useEffect(() => {
		setChecked(check);
	}, [check]);

	return (
		<>
			<CheckBox checked={checked} onChange={() => selectItem(item.aCode)} />
			<div className="flex items-center justify-start">
				<div className="rounded-full bg-light-dew p-2 mr-2">
					<AccountIcon height={20} width={20} />
				</div>
				<div className="flex flex-row items-center">
					{zuname} {vorname}
				</div>
			</div>
			<div className="flex items-center justify-start">
				{telNrWeitergabe === -1 ? (
					<div className="flex flex-row items-center">
						<LinkWrapper customStyles="flex flex-row items-center" link={`tel:${mobil}`}>
							<PhoneIcon height={20} stroke="var(--colors-primary-blue)" width={20} />
							<span className="ml-2">{mobil}</span>
						</LinkWrapper>
					</div>
				) : (
					<div className="flex flex-row items-center">
						<span className="text-silver-sand">-</span>
					</div>
				)}
			</div>
		</>
	);
};
export default VertretungItem;
