import React, { useCallback, useMemo } from 'react';
import { useExpanded, useTable, Row } from 'react-table';
import { NoData } from '@components/Static';
import { AnimateHeight } from '@components/Animation';
import Loader from '@components/Loader';
import { TableExpandArrow } from '@components/index';
import clsx from 'clsx';
import { Select } from '@components/Form';
import Pagination from '@components/Pagination';
import { ArrowSharpIcon } from '@assets/icons';
import styles from './TableComponent.module.scss';

interface InitialSortingInterface {
	id: string;
	desc: boolean;
}
// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-unused-vars
interface TableComponentInterface<T extends object = {}> {
	cols: HeaderItemInterface[];
	data: any;
	renderRowSubComponent?: (a: any) => React.ReactNode;
	label?: string;
	loading?: boolean;
	onClick?: (p: any) => void;
	initialSortBy?: InitialSortingInterface[];
	manualPageSize?: number;
	onSorting?: (p: any) => void;
	pagination?: {
		pageCount: string;
		setPageCount: (p: any) => void;
		pageNumber: string;
		setPageNumber: (p: any) => void;
		pagesLength: number;
	};
	hiddenColumns?: string[];
	withExpander?: boolean;
	sortingState?: {
		reverse: boolean;
		name: string;
	};
}

export interface HeaderItemInterface {
	Header: string;
	accessor: string;
	width?: string;
	sorting?: string;
}

export const pageSizeOptions = [10, 20, 30, 40, 50].map(item => ({
	label: `${item} per page`,
	value: `${item}`,
}));

const variants = {
	open: {
		opacity: 1,
		height: 'auto',
	},
	collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
};

// eslint-disable-next-line @typescript-eslint/ban-types
const TableComponent = <T extends object>({
	cols,
	data,
	renderRowSubComponent,
	label,
	loading,
	onClick,
	onSorting,
	manualPageSize,
	pagination,
	sortingState,
	withExpander = false,
}: TableComponentInterface<T>) => {
	const columnsWithEventClick = useMemo(
		() =>
			cols.map(item => ({
				...item,
				width: item?.width ? item?.width : 'auto',
				Header: ({ column }: any) => {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const itemHeader = cols.find(item => item.accessor === column.id);
					// const isSorting = itemHeader?.Header === sortingState?.name;
					return (
						<div className={clsx('flex items-center bg-pampas text-rainy-gray font-normal text-left px-4 py-5')}>
							{itemHeader?.Header}
							{itemHeader?.sorting && onSorting ? (
								<div
									className="p-1 cursor-pointer hover hover:opacity-80 transition duration-75"
									onClick={() => onSorting(itemHeader?.sorting)}
								>
									<ArrowSharpIcon
										className={clsx('transform ml-1 mb-0.5', sortingState?.reverse ? 'rotate-0' : 'rotate-180')}
										height={15}
										width={15}
									/>
								</div>
							) : (
								<></>
							)}
						</div>
					);
				},
				Cell: ({ value, row }: any) => {
					if (onClick) {
						return (
							<div
								className="flex-1 px-4 py-5 flex items-center cursor-pointer group-hover:bg-primary-blue-green group-hover:bg-opacity-20 transition duration-75 break-all"
								onClick={() => onClick(row.original)}
							>
								<span>{value}</span>
							</div>
						);
					}
					return (
						<div className="flex-1 px-4 py-5 flex items-center break-all">
							<span>{value}</span>
						</div>
					);
				},
			})),
		[cols, sortingState],
	);

	const columns = useMemo(() => {
		if (withExpander) {
			return [
				...columnsWithEventClick,
				{
					id: 'expander',
					// @ts-ignore
					// eslint-disable-next-line react/display-name
					Cell: ({ row }: any) => {
						return row.canExpand ? <TableExpandArrow className="w-full flex items-center" row={row} /> : null;
					},
				},
			];
		}
		return [...columnsWithEventClick];
	}, [columnsWithEventClick, withExpander]);

	const { visibleColumns, getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: manualPageSize,
			},
		},
		useExpanded,
	);

	const viewPagination = useCallback(() => {
		if (pagination) {
			const { setPageCount, pageCount } = pagination;
			return (
				<div className="flex flex-row items-center justify-between pt-4">
					<Select
						options={pageSizeOptions}
						position="topPosition"
						value={pageSizeOptions.find(item => item.value === pageCount) || pageSizeOptions[0]}
						onChange={value => setPageCount(value)}
					/>
					<Pagination pagination={pagination} />
				</div>
			);
		}
		return <></>;
	}, [pagination]);

	const renderTable = useCallback(() => {
		if (loading) {
			return (
				<tr className="flex-1 self-stretch flex-grow flex items-center justify-center">
					<td className="flex flex-1 items-center justify-center">
						<Loader height={50} width={50} />
					</td>
				</tr>
			);
		}
		if (data && !data.length) {
			return (
				<tr className="flex-1 self-stretch flex-grow flex items-center justify-center">
					<td className="flex flex-1 items-center justify-center">
						<NoData title={label} />
					</td>
				</tr>
			);
		}
		// eslint-disable-next-line @typescript-eslint/ban-types
		return rows.map((row: Row<object>) => {
			prepareRow(row);
			const bgLine = +row.id % 2 ? 'bg-white' : 'bg-alabaster';
			return (
				<React.Fragment key={row.getRowProps().key}>
					{
						// @ts-ignore
						row.depth === 0 && (
							<tr className={clsx(bgLine, 'group')}>
								{row.cells.map(cell => {
									return (
										// eslint-disable-next-line react/jsx-key
										<td
											{...cell.getCellProps({
												style: {
													minWidth: cell.column.minWidth,
													width: cell.column.width,
													maxWidth: cell.column.maxWidth,
												},
											})}
										>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						)
					}
					{
						// @ts-ignore
						renderRowSubComponent && row.depth === 0 && (
							<tr>
								<td colSpan={visibleColumns.length}>
									<AnimateHeight
										className="flex flex-1"
										// @ts-ignore
										isVisible={!!row?.isExpanded}
										variants={variants}
									>
										{renderRowSubComponent({ row })}
									</AnimateHeight>
								</td>
							</tr>
						)
					}
				</React.Fragment>
			);
		});
	}, [columns.length, data, label, loading, prepareRow, renderRowSubComponent, rows, visibleColumns.length]);

	return (
		<div className="flex-1 flex flex-col self-stretch overflow-hidden">
			<table className={clsx(styles.table)} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						// eslint-disable-next-line react/jsx-key
						<tr className="group" {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								// if (!showExpanderColumn && column.id === 'expander') return null;
								return (
									// eslint-disable-next-line react/jsx-key
									<th
										{...column.getHeaderProps({
											style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
										})}
									>
										{column.render('Header')}
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>{renderTable()}</tbody>
			</table>
			{viewPagination()}
		</div>
	);
};

export default TableComponent;
