import React, { SVGProps } from 'react';

interface LogoIconInterface extends SVGProps<SVGSVGElement> {
	withColor?: boolean;
	stroke?: string;
}

function LogoIcon({ withColor, stroke = '#fff', ...props }: LogoIconInterface) {
	if (withColor) {
		return (
			<svg fill="none" viewBox="0 0 63 64" xmlns="http://www.w3.org/2000/svg" {...props}>
				<path
					d="M31.546 6.186c-14.434 0-26.135 11.7-26.135 26.134 0 14.434 11.7 26.134 26.135 26.134 14.433 0 26.134-11.7 26.134-26.134"
					stroke="#37B899"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={5.227}
				/>
				<path
					d="M31.546 48c8.66 0 15.68-7.021 15.68-15.68 0-8.66-7.02-15.681-15.68-15.681s-15.68 7.02-15.68 15.68"
					stroke="#2394AB"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={5.227}
				/>
			</svg>
		);
	}
	return (
		<svg fill="none" viewBox="0 0 115 115" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M57.742 10.052c-26.338 0-47.688 21.35-47.688 47.688s21.35 47.688 47.688 47.688 47.688-21.35 47.688-47.688"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={9.538}
			/>
			<path
				d="M57.739 86.353c15.801 0 28.613-12.812 28.613-28.613 0-15.802-12.812-28.613-28.613-28.613-15.802 0-28.613 12.811-28.613 28.613"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={9.538}
			/>
		</svg>
	);
}

export default LogoIcon;
