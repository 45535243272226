import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { AssistantDataInterface } from '@components/Tables/TermineAssistantTable/useAssistantDataTable';
import { useGetReplacementCandidatesMutation, useSetReplacementCandidatesMutation } from '@api';
import {
	VertretungActions,
	VertretungInitialState,
	VertretungItemInterface,
	VertretungTypesEnum,
} from '@components/Vertretung/Vertretung.types';
import VertretungItem from '@components/Vertretung/VertretungItem';
import { CheckBox } from '@components/Form';
import Loader from '@components/Loader';
import { Button } from '@components/index';
import { ButtonVariantEnum } from '@components/Button/types';
import { unionBy } from 'lodash';
import styles from './Vertretung.module.scss';

const userReducer = (state: VertretungInitialState, action: VertretungActions) => {
	switch (action.type) {
		case VertretungTypesEnum.SingleSelect:
			return {
				...state,
				candidates: [
					...(state.candidates.find(item => item === action.payload[0])
						? state.candidates.filter(item => item !== action.payload[0])
						: [...state.candidates, action.payload[0]]),
				],
			};
		case VertretungTypesEnum.MultipleSelect:
			return {
				...state,
				candidates: [...(action.payload.length > state.candidates.length ? [...action.payload] : [])],
			};
		default:
			return state;
	}
};

const initialState = (id: AssistantDataInterface['id']): VertretungInitialState => ({
	candidates: [],
	termineId: id,
});

const Vertretung = ({ id }: AssistantDataInterface) => {
	const [getAssistants, { data, isLoading: getAssistantLoading, isSuccess }] = useGetReplacementCandidatesMutation();
	const [setAssistants, { isLoading: setAssistantLoading }] = useSetReplacementCandidatesMutation();
	const [allUsers, setAllUsers] = useState<VertretungItemInterface[]>([]);
	const [state, dispatch] = useReducer(userReducer, initialState(id));

	useEffect(() => {
		getAssistants({
			TermineId: id,
			WithoutRelations: false,
		});
	}, [getAssistants, id]);

	useEffect(() => {
		if (isSuccess) {
			const { selectedAssistantACodes, availableAssistant } = data.data;
			setAllUsers(prevState =>
				availableAssistant.length ? unionBy(availableAssistant, prevState, 'aCode') : prevState,
			);
			if (selectedAssistantACodes && !!selectedAssistantACodes.length) {
				dispatch({
					type: VertretungTypesEnum.MultipleSelect,
					payload: selectedAssistantACodes,
				});
			}
		}
	}, [data?.data, isSuccess]);

	const renderAllUser = useCallback(() => {
		if (getAssistantLoading || setAssistantLoading) {
			return <Loader className="mx-auto flex-1" height={50} width={50} />;
		}
		if (!allUsers.length) {
			return (
				<div className="mx-auto my-auto flex-1">
					<p className=" text-center text-contrast-raven">Not users</p>
				</div>
			);
		}
		return (
			<>
				<div className={`${styles.vertretung} mb-6`}>
					<CheckBox
						checked={allUsers.length === state.candidates.length}
						onChange={() =>
							dispatch({ type: VertretungTypesEnum.MultipleSelect, payload: allUsers.map(item => item.aCode) })
						}
					/>
					<div className="flex items-center justify-start text-silver-sand">Name</div>
					<div className="flex items-center justify-start text-silver-sand">Contacts</div>
					{allUsers.map((user: VertretungItemInterface, index) => (
						<VertretungItem
							check={state.candidates.includes(user.aCode)}
							item={user}
							key={index}
							selectItem={aCode => dispatch({ type: VertretungTypesEnum.SingleSelect, payload: [aCode] })}
						/>
					))}
				</div>
			</>
		);
	}, [allUsers, getAssistantLoading, setAssistantLoading, state.candidates]);

	return (
		<div className={`bg-white p-10 rounded-xl flex flex-col ${styles.modal}`}>
			<h3 className="text-2xl text-center font-semiBold mb-8 text-contrast-color">Vertretung</h3>
			{renderAllUser()}
			<div className="flex flex-col items-center">
				<Button
					className="mb-4 px-6 py-3 uppercase"
					disabled={getAssistantLoading}
					text="Frei Assistenten"
					variant={ButtonVariantEnum.flat}
					onClick={() =>
						getAssistants({
							TermineId: id,
							WithoutRelations: true,
						})
					}
				/>
				<Button
					className="mb-4 px-6 py-3 rounded-full"
					disabled={getAssistantLoading || !state.candidates.length}
					text="Speichern"
					variant={ButtonVariantEnum.default}
					onClick={() => setAssistants(state)}
				/>
			</div>
		</div>
	);
};
export default Vertretung;
