import { USER_ROLES } from '@utils/consts';
import RootLayout from '@components/Layout/RootLayout';
import AuthLayout from '@components/Layout/AuthLayout';
import { ASSISTANT_ROUTES } from '@router/assistant';
import { RoleType } from '@api/user';
import { ADMIN_ROUTES } from '@router/admin';
import RedirectPage from '@pages/Redirect';
import { ROOT_ROUTES } from './root';
import { AUTH_ROUTES } from './auth';
import { CLIENT_ROUTES } from './client';

export const getRoutes = (userRole?: RoleType | null) => {
	const routesByRole = {
		[USER_ROLES.CLIENT_SECOND_ROLE]: CLIENT_ROUTES,
		[USER_ROLES.CLIENT_ROLE]: CLIENT_ROUTES,
		[USER_ROLES.ASSISTANT_ROLE]: ASSISTANT_ROUTES,
		[USER_ROLES.ADMIN_ROLE]: ADMIN_ROUTES,
	};

	if (userRole === USER_ROLES.USER_ROLE) {
		return [
			{
				component: RootLayout,
				routes: [
					...ROOT_ROUTES,
					{
						name: 'not-found',
						path: '*',
						Icon: null,
						exact: true,
						component: RedirectPage,
					},
				],
			},
		];
	}

	if (userRole) {
		return [
			{
				component: RootLayout,
				routes: [
					...ROOT_ROUTES,
					...routesByRole[userRole],
					{
						name: 'not-found',
						path: '*',
						Icon: null,
						exact: true,
						component: RedirectPage,
					},
				],
			},
		];
	}
	return [
		{
			component: AuthLayout,
			routes: AUTH_ROUTES,
		},
	];
};
