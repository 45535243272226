import React from 'react';
import { DrawerSliderComponents } from '@components/Drawer/Drawer.types';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrawerSlider, setStateDrawer } from '@store/main';
import { WIDTH_MENU } from '@utils/consts';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Drawer.module.scss';

const variants = {
	init: {
		opacity: 0,
		x: 150,
	},
	enter: {
		opacity: 1,
		x: 0,
		transition: {
			x: {
				ease: 'easeInOut',
				duration: 0.3,
				delay: 0.3,
			},
			opacity: {
				ease: 'easeInOut',
				duration: 0.6,
				delay: 0.3,
			},
		},
	},
	exit: {
		x: 150,
		opacity: 0,
	},
};

const Drawer = () => {
	const { name, state } = useSelector(selectDrawerSlider);
	// @ts-ignore
	const ViewComponent = DrawerSliderComponents[name];
	const dispatch = useDispatch();

	return (
		<AnimatePresence>
			{state && (
				<motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
					<div className={styles['drawer-wrapper']} style={{ left: WIDTH_MENU }}>
						<div
							className="bg-blackout bg-opacity-30 absolute w-full h-full z-10 absolute"
							onClick={() => dispatch(setStateDrawer({ name, state: false }))}
						/>
						<motion.div
							animate="enter"
							className="h-full absolute top-0 right-0 bottom-0 w-5/12 bg-white z-20"
							exit="exit"
							initial="init"
							variants={variants}
						>
							<ViewComponent />
						</motion.div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default Drawer;
