export enum VertretungTypesEnum {
	SingleSelect = 'SINGLE_SELECT',
	MultipleSelect = 'MULTIPLY_SELECT',
}

export type VertretungInitialState = {
	candidates: VertretungItemInterface['aCode'][];
	termineId: number;
};

export interface VertretungItemInterface {
	mobil: string;
	zuname: string;
	vorname: string;
	telNrWeitergabe: 0 | 1 | -1;
	aCode: string;
}

export type VertretungActions = {
	type: VertretungTypesEnum;
	payload: VertretungItemInterface['aCode'][];
};
