import React, { useCallback } from 'react';
import { useGetAllNotificationsQuery } from '@api';
import NotificationsItem from '@components/Notifications/NotificationsItem';
import { PerfectScrollbar, SkeletonView } from '@components';

const NoNotification = () => {
	return (
		<div className="container h-full mx-auto flex flex-row items-center justify-center">
			<p className="text-2xl text-contrast-raven">No Benachrichtigungen</p>
		</div>
	);
};

const Notifications = () => {
	const { data, isLoading } = useGetAllNotificationsQuery();

	const renderList = useCallback(() => {
		if (isLoading)
			return [1, 2, 3].map(index => <SkeletonView className="mb-6 rounded-2xl" height={134} key={index} />);
		if (!data?.data?.length) return <NoNotification />;
		return data?.data.map(item => (
			<div className="mb-6" key={item.id}>
				<NotificationsItem data={item} />
			</div>
		));
	}, [data?.data, isLoading]);

	return (
		<div className="flex flex-col h-full">
			<h3 className="text-2xl font-semiBold text-silver-sand mb-6 text-left p-6">Benachrichtigungen</h3>
			{/* <h4 className="text-lg text-primary-blue font-semiBold mb-4 px-6">Ungelesene Benachrichtigungen</h4> */}
			<PerfectScrollbar className="mx-3 p-3">
				<>{renderList()}</>
			</PerfectScrollbar>
		</div>
	);
};
export default Notifications;
