import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@utils/http';
import { baseAppUrl } from '@config/api';

export const rootSplitApiLanding = createApi({
	reducerPath: 'rootReducerLanding',
	baseQuery: axiosBaseQuery({ baseUrl: baseAppUrl }),
	tagTypes: ['News'],
	endpoints: () => ({}),
});
