import * as React from 'react';

function ArrowIcon({ stroke = '#87D0CE', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M6.5 11l-5-5 5-5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
		</svg>
	);
}

export default ArrowIcon;
