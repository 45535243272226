import React, { useCallback, useRef, useState } from 'react';
import { AnimateHeight } from '@components/Animation';
import { ArrowIcon } from '@assets/icons';
import { Element, scroller } from 'react-scroll/modules';
import { ClockIcon } from '@assets/icons/menu';
import { useOutside } from '@hooks/useOutside';
import moment from 'moment';
import styles from './TimePicker.module.scss';
import { minutesData, hourData } from './dataPicker';

interface TimePickerInterface {
	onChange: (arg0: string) => void;
	label?: string;
	className?: string;
	scrollID?: string;
	error?: string;
	time?: string | number;
}

const TimePicker = ({ onChange, label, className, scrollID = 'default', error, time }: TimePickerInterface) => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState({
		hours: time ? moment(time, 'HH:mm').format('HH') : '00',
		minutes: time ? moment(time, 'HH:mm').format('mm') : '00',
	});

	const wrapperRef = useRef(null);
	useOutside(wrapperRef, setIsOpen);

	const sendFormat = useCallback(
		(sendData: { hours: string; minutes: string }) => `${sendData.hours}:${sendData.minutes}`,
		[],
	);

	const onClick = useCallback(
		(value: string, type: 'hours' | 'minutes', name?: string | undefined, container?: string | undefined) => {
			if (name && container) {
				scroller.scrollTo(name, {
					duration: 300,
					smooth: true,
					containerId: container,
					offset: 0,
				});
			}

			setData({ ...data, [type]: value });
			onChange(sendFormat({ ...data, [type]: value }));
		},
		[data, onChange, sendFormat],
	);

	return (
		<div className={`relative ${className || ''}`}>
			{!!label && <p className="text-silver-sand mb-1 text-sm">{label}</p>}
			<div
				className={`flex relative z-20 bg-white mb-1 cursor-pointer justify-center items-center bg-light-dew py-2 px-3 rounded-xl
					${isOpen ? 'pointer-events-none' : ''}
					${error ? 'shadow-redShadow' : ''}
				`}
				onClick={() => setIsOpen(!isOpen)}
			>
				<ClockIcon className="stroke-current text-primary-blue" height={28} width={28} />
				<div className={`text-contrast-color mx-2 ${styles['picker-label']}`}>{sendFormat(data)}</div>
				<ArrowIcon
					className="transform transition delay-100 rotate-270"
					height={15}
					stroke="var(--colors-primary-blue)"
					width={15}
				/>
			</div>
			<AnimateHeight
				className={`absolute z-20 flex flex-row w-full bg-white rounded-xl ${styles.picker} top-full shadow`}
				isVisible={isOpen}
				variants={{
					open: {
						opacity: 1,
						visibility: 'visible',
						translateY: '10px',
					},
					collapsed: { opacity: 0, visibility: 'hidden', translateY: '20px' },
				}}
			>
				<div className="flex flex-row flex-1" ref={wrapperRef}>
					<div
						className="flex-1 overflow-y-hide border-r-2 border-solid border-white"
						id={`hours-container-${scrollID}`}
					>
						{hourData.map((value, index) => (
							<Element
								className={`${
									data.hours === value ? 'bg-light-dew' : ''
								} text-center rounded-xl border-b-2 border-solid border-white py-2 px-4 cursor-pointer hover:bg-light-dew transition duration-100`}
								key={index}
								name={`hours-${value}`}
								onClick={() => onClick(value, 'hours', `hours-${value}`, `hours-container-${scrollID}`)}
							>
								{value}
							</Element>
						))}
					</div>
					<ul className="flex-1 ">
						{minutesData.map((value, index) => (
							<li
								className={`${
									data.minutes === value ? 'bg-light-dew' : ''
								} text-center rounded-xl border-b-2 border-solid border-white py-2 px-4 cursor-pointer hover:light-dew transition duration-100`}
								key={index}
								onClick={() => onClick(value, 'minutes')}
							>
								{value}
							</li>
						))}
					</ul>
				</div>
			</AnimateHeight>
		</div>
	);
};
export default TimePicker;
