import { createSelector, createSlice } from '@reduxjs/toolkit';
import { InitialMainStoreInterface } from '@store/main/types';
import type { RootState } from '../index';

export const MainInitialState = <InitialMainStoreInterface>{
	drawer: {
		name: 'notification',
		state: false,
	},
};

const mainSlice = createSlice({
	name: 'main',
	initialState: MainInitialState,
	reducers: {
		setStateDrawer: (state, action) => {
			state.drawer = action.payload;
		},
	},
});

export const { setStateDrawer } = mainSlice.actions;

export default mainSlice.reducer;

export const selectClientState = (state: RootState) => state.main;
export const selectDrawerSlider = createSelector([selectClientState], main => main.drawer);
