import * as React from 'react';

function LogoutIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<g opacity={0.8} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
				<path d="M16 17l5-5m-5-5l2 2M21 12H9M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4" />
			</g>
		</svg>
	);
}

export default LogoutIcon;
