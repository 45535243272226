import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './CheckBox.module.scss';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	label?: string;
}

const CheckBox = ({ onChange, label, checked = false }: CheckboxProps) => {
	return (
		<label className={clsx(styles.checkbox, 'checkbox flex items-center')}>
			<span className={styles.checkbox__input}>
				<input checked={checked} name="checkbox" type="checkbox" onChange={onChange} />
				<span className={styles.checkbox__control}>
					<svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.73 12.91l6.37 6.37L22.79 4.59" fill="none" stroke="currentColor" strokeWidth="3" />
					</svg>
				</span>
			</span>
			{label && <span className="text-sm color-black ml-4">{label}</span>}
		</label>
	);
};
export default CheckBox;
