import * as React from 'react';

function UploadIcon({ ...props }) {
	return (
		<svg fill="none" height={26} width={26} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21.876 15.626v4.167a2.084 2.084 0 01-2.083 2.083H5.209a2.084 2.084 0 01-2.084-2.083v-4.167M7.292 10.417l5.209 5.209m5.208-5.209l-2.083 2.084M12.5 15.626v-12.5"
				stroke="#2394AB"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2.083}
			/>
		</svg>
	);
}

export default UploadIcon;
