import * as React from 'react';

function BerichtIcon({ ...props }) {
	return (
		<svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 18h13M3 18h.01M8 12h13M3 12h.01M8 6h13M3 6h.01"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
		</svg>
	);
}

export default BerichtIcon;
