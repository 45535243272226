import * as React from 'react';

function EuroIcon({ fill = '#2394AB', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.513 3.25c1.966 0 3.821.843 5.224 2.373l2.028-1.859C18.835 1.66 16.259.5 13.513.5c-2.747 0-5.321 1.16-7.249 3.266A11.047 11.047 0 004.06 7.375H.5v2.75h2.907c-.053.454-.088.912-.088 1.375 0 .463.035.921.088 1.375H.5v2.75h3.56a11.028 11.028 0 002.204 3.61c1.928 2.106 4.502 3.265 7.249 3.265 2.746 0 5.321-1.16 7.25-3.264l-2.028-1.86c-1.4 1.531-3.256 2.374-5.222 2.374-1.966 0-3.82-.841-5.222-2.373a8.166 8.166 0 01-1.19-1.752h5.774v-2.75H6.191A9.095 9.095 0 016.07 11.5c0-.467.053-.924.122-1.375h6.684v-2.75H7.101c.33-.628.71-1.228 1.19-1.752 1.402-1.53 3.256-2.373 5.222-2.373z"
				fill={fill}
			/>
		</svg>
	);
}

export default EuroIcon;
