import { motion } from 'framer-motion';
import React from 'react';
import { Variants } from 'framer-motion/types/types';

interface AnimateHeightPropsInterface {
	isVisible: boolean;
	ease?: string;
	duration?: number;
	className?: string;
	variants?: Variants;
	children: React.ReactNode;
}

export function AnimateHeight({
	duration,
	ease = 'easeOut',
	variants = {
		open: {
			opacity: 1,
			height: 'auto',
		},
		collapsed: { opacity: 0, height: 0 },
	},
	isVisible,
	className,
	children,
	...other
}: AnimateHeightPropsInterface) {
	return (
		<motion.div
			animate={isVisible ? 'open' : 'collapsed'}
			className={`overflow-hidden ${className || ''}`}
			inherit={false}
			initial={isVisible ? 'open' : 'collapsed'}
			transition={{
				ease,
				duration,
			}}
			variants={variants}
			{...other}
		>
			{children}
		</motion.div>
	);
}
export default AnimateHeight;
