import React, { useState } from 'react';
import { USER_ROLES } from '@utils/consts';
import { useSelector } from 'react-redux';
import { selectUserRole } from '@store/auth';
import {
	NotificationComponentWithTypeInterface,
	NotificationsItemInterface,
} from '@components/Notifications/Notification.types';
import { NotificationFinding, NotificationFound, NotificationNotReplacement } from '@components/Notifications/status';

const ViewNotificationByStatus = {
	found: (props: NotificationComponentWithTypeInterface) => <NotificationFound {...props} />,
	finding: (props: NotificationComponentWithTypeInterface) => <NotificationFinding {...props} />,
	notReplace: (props: NotificationComponentWithTypeInterface) => <NotificationNotReplacement {...props} />,
};

const NotificationsItem = ({ data }: NotificationsItemInterface) => {
	const [type, setType] = useState(data?.type ? data.type : 'finding');
	const role = useSelector(selectUserRole);
	const userData = role === USER_ROLES.ASSISTANT_ROLE ? 'assistent' : 'klient';
	const Component = ViewNotificationByStatus[data.type] || null;

	if (!Component) return <></>;

	return (
		<div className="px-4 py-6 bg-white rounded-lg shadow-xs">
			<Component id={data.id} setType={setType} termine={data.termine} type={type} user={data[userData]} />
		</div>
	);
};

export default NotificationsItem;
