import { rootSplitApi } from '@api/rootSplitApi';
import { ResponseConstructInterface } from '@api/types';

export interface DocumentInterface {
	anmerkungen: string;
	category: string;
	displayName: string;
	erstellungsdatum: string;
	fileName: string;
	id: number;
	änderungsdatum: string;
}

type DocumentCategoryItem = {
	ereignis: string;
	ereignisArtID: number;
};

export const fileHandlerApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		getAllDocuments: builder.query<any, void>({
			query: () => ({
				url: 'FileHandler/Get',
				method: 'GET',
			}),
			providesTags: result =>
				result
					? [
							...result.data.map(({ id }: DocumentInterface) => ({ type: 'Documents', id } as const)),
							{ type: 'FileHandler', id: 'LIST' },
					  ]
					: [{ type: 'FileHandler', id: 'LIST' }],
		}),
		getDocumentById: builder.mutation<any, number>({
			query: id => ({
				url: `FileHandler/Get/${id}`,
				method: 'GET',
			}),
		}),
		deleteDocument: builder.mutation<any, number>({
			query: id => ({
				url: `FileHandler/delete/${id}`,
				method: 'POST',
			}),
			invalidatesTags: (result, error, id) => [{ type: 'FileHandler', id }],
		}),
		getDocumentCategories: builder.query<ResponseConstructInterface<DocumentCategoryItem[]>, void>({
			query: () => ({
				url: 'FileHandler/GetCategories',
				method: 'GET',
			}),
			providesTags: [{ type: 'FileHandler', id: 'CATEGORIES' }],
		}),
		createDocument: builder.mutation<any, number>({
			query: data => ({
				url: `FileHandler/Create`,
				method: 'POST',
				data,
			}),
			invalidatesTags: () => [{ type: 'FileHandler', id: 'LIST' }],
		}),
		removeDocument: builder.mutation<any, number>({
			query: id => ({
				url: `FileHandler/delete/${id}`,
				method: 'POST',
			}),
			invalidatesTags: [{ type: 'FileHandler', id: 'LIST' }],
		}),
	}),
});

export const {
	useGetAllDocumentsQuery,
	useGetDocumentByIdMutation,
	useDeleteDocumentMutation,
	useGetDocumentCategoriesQuery,
	useCreateDocumentMutation,
	useRemoveDocumentMutation,
} = fileHandlerApi;
