import * as React from 'react';

function BenachrichtigungenIcon({ ...props }) {
	return (
		<svg fill="none" height={22} viewBox="0 0 18 22" width={18} xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9.721 4.003L8.255 4c-3.344-.008-6.247 2.709-6.27 6v3.79c0 .79-.1 1.561-.53 2.218l-.288.438C.73 17.11 1.2 18 1.985 18h14.03c.785 0 1.254-.89.818-1.554l-.287-.438c-.43-.657-.53-1.429-.53-2.219v-3.788c-.04-3.292-2.95-5.99-6.295-5.998v0zM12 18a3 3 0 01-6 0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
			/>
			<path d="M9 1a2 2 0 012 2v1H7V3a2 2 0 012-2z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
		</svg>
	);
}

export default BenachrichtigungenIcon;
