import { rootSplitApi } from '@api/rootSplitApi';
import { USER_ROLES } from '@utils/consts';
import { ResponseInterface } from '@api/types';

export type RoleType = typeof USER_ROLES[keyof typeof USER_ROLES];

export interface UserInterface {
	code: string | null;
	email: string | null;
	firstName: string | null;
	lastName: string | null;
	roles: Array<RoleType> | [];
	token: string | null;
	isAuth: boolean;
}

export interface ChangeAccountInterface {
	street: string;
	plz: string;
	ort: string;
	addressMaps: string;
	mobil: string;
}

export interface AccountInfo extends ResponseInterface {
	data: {
		addressMaps: string;
		mobil: string;
		ort: string;
		plz: string;
		street: string;
	};
}

export interface LoginRequest {
	email: string;
	password: string;
	rememberMe: boolean;
}

export const accountApi = rootSplitApi.injectEndpoints({
	endpoints: builder => ({
		login: builder.mutation<UserInterface, LoginRequest>({
			query: credentials => ({
				url: 'Account/Login',
				method: 'POST',
				data: credentials,
			}),
		}),
		register: builder.mutation<UserInterface, LoginRequest>({
			query: credentials => ({
				url: 'Account/Register',
				method: 'POST',
				data: credentials,
			}),
		}),
		accountInfo: builder.query<AccountInfo, void>({
			query: () => ({
				url: 'Account/info',
				method: 'GET',
			}),
			providesTags: ['Account'],
		}),
		changeAccountInfo: builder.mutation<any, ChangeAccountInterface>({
			query: credentials => ({
				url: 'Account/change',
				method: 'POST',
				data: credentials,
			}),
			invalidatesTags: ['Account'],
		}),
	}),
});

export const { useLoginMutation, useRegisterMutation, useAccountInfoQuery, useChangeAccountInfoMutation } = accountApi;
