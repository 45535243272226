import React, { ButtonHTMLAttributes } from 'react';

export enum ButtonVariantEnum {
	default = 'btn-primary',
	secondary = 'btn-secondary',
	secondaryRed = 'btn-secondary-red',
	flat = 'btn-flat',
	landing = 'btn-landing',
	landingDark = 'btn-landing-dark',
}

export interface ButtonPropsInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: ButtonVariantEnum;
	ripple?: boolean;
	simple?: boolean;
	rounded?: boolean;
	text?: string;
	loading?: boolean;
	children?: React.ReactNode;
}
