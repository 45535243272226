import * as React from 'react';

function PlusIcon({ ...props }) {
	return (
		<svg fill="none" viewBox="0 0 496 496" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g mask="url(#prefix__a)">
				<path
					d="M112.034 144.917c-22.542.805-61.457 48.381-80.417 72.571L290.52 477.371c26.152-24.844 79.632-77.082 84.34-87.281 4.707-10.199-21.576-30.402-35.305-39.228l-86.301-90.223c-21.248-26.479-67.471-82.77-82.378-96.108-18.633-16.672-31.382-20.594-58.841-19.614z"
					fill="#27277A"
				/>
				<path
					d="M123.957 193.014c-15.94 30.968-55.126 47.974-72.726 52.606l190.283 189.581c10.959-.993 41.444-9.529 75.715-35.733 42.839-32.754 104.307-20.844 100.621-57.569-.996-9.925-15.94-19.851-38.854-27.792-18.331-6.352-39.518-5.955-47.82-4.962l-47.82 23.821-28.891-50.621 23.91-67.494c-.332-44.997-1.594-136.975-3.985-144.915-2.989-9.926-22.914-29.777-39.85-45.659-16.936-15.88-47.82-14.888-53.797-7.94-5.978 6.948-3.985 20.844 11.955 57.569s3.985 46.651-5.978 53.599c-9.962 6.948-12.951-2.978-32.876 4.962-19.925 7.941-9.962 21.837-29.887 60.547z"
					fill="#1066FF"
				/>
				<path
					d="M372.9 316.095c-9.415-2.354-14.384 3.596-15.692 6.865-.125.296 7.846 1.961 14.711 1.961s9.807 4.904 16.672 9.807c6.864 4.903 15.691 5.884 18.633 5.884 2.942 0 2.942-3.923 0-8.826-2.942-4.904-22.556-12.749-34.324-15.691zM187.554 333.21c-6.623-5.038-30.523 2.901-32.363 7.7l26.675 26.991c4.293 1.8 16.925 8.458 33.114 20.694 20.237 15.295 56.11 14.395 80.026 11.696 23.915-2.699 39.552-25.192 38.632-34.189-.919-8.997-6.438-1.8-50.59 7.198-44.152 8.997-58.869 8.097-76.346 0-17.477-8.098-10.869-33.792-19.148-40.09z"
					fill="#04D"
				/>
				<path
					d="M192.455 314.576c-11.769-4.707-36.286 8.499-47.073 15.691-9.807-9.48-29.421-28.636-29.421-29.421 0-.98 12.749-5.884 24.517-11.768 9.415-4.707 18.96-13.076 22.556-16.672l85.32 9.807 13.73 25.498c-.981 11.768-5.1 36.286-13.73 40.208-10.788 4.904-31.382 5.884-38.247 0-6.865-5.884-2.942-27.459-17.652-33.343z"
					fill="#27277A"
				/>
				<path
					d="M232.663 114.35c2.353 10.199 8.172 10.787 10.787 9.807 9.48-15.364 28.44-47.27 28.44-51.977 0-5.884-20.594-29.42-33.343-40.208-12.749-10.788-34.324-6.865-38.247 0-3.923 6.865 10.787 20.594 19.614 30.401 8.826 9.807 9.807 39.228 12.749 51.977zM100.268 288.099l-29.42-29.587 42.169-8.826 25.498-11.768 30.402 5.884-68.649 44.297z"
					fill="#04D"
				/>
				<path
					d="M105.168 185.409c3.596-.327 14.906-2.048 19.614-13.031 5.884-13.73 2.942-40.209 21.575-47.073 14.906-5.492 25.825-.654 27.459 0"
					stroke="#004EF8"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={3.923}
				/>
				<path d="M47.309 213.565l19.613 18.634" stroke="#004EF8" strokeLinecap="round" strokeWidth={3.923} />
				<path
					d="M274.824 446.97l17.653 17.652"
					stroke="#004EF8"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={3.923}
				/>
				<path
					d="M288.555 448.932l9.807 10.787M180.062 299.236c2.765-.516 9.549-1.156 14.574.411 5.024 1.567 9.161 7.451 10.602 10.198"
					stroke="#004EF8"
					strokeLinecap="round"
					strokeWidth={3.923}
				/>
			</g>
			<g clipPath="url(#prefix__clip0)">
				<path
					clipRule="evenodd"
					d="M261.098 74.31c-19.499 0-35.305 15.807-35.305 35.305v74.533h-74.535c-19.498 0-35.305 15.806-35.305 35.305v25.498c0 19.498 15.807 35.304 35.305 35.304h74.535v74.533c0 19.498 15.806 35.305 35.305 35.305h25.498c19.498 0 35.305-15.807 35.305-35.305v-74.533h74.529c19.499 0 35.305-15.806 35.305-35.304v-25.498c0-19.499-15.806-35.305-35.305-35.305h-74.529v-74.533c0-19.498-15.807-35.304-35.305-35.304h-25.498z"
					fill="#44877A"
					fillRule="evenodd"
				/>
				<mask height={317} id="prefix__b" maskUnits="userSpaceOnUse" width={317} x={135} y={54}>
					<path
						clipRule="evenodd"
						d="M280.703 54.696c-19.498 0-35.305 15.807-35.305 35.305v74.533h-74.535c-19.498 0-35.304 15.806-35.304 35.304v25.498c0 19.499 15.806 35.305 35.304 35.305h74.535v74.533c0 19.498 15.807 35.304 35.305 35.304h25.498c19.499 0 35.305-15.806 35.305-35.304v-74.533h74.53c19.498 0 35.305-15.806 35.305-35.305v-25.498c0-19.498-15.807-35.304-35.305-35.304h-74.53V90.001c0-19.498-15.806-35.305-35.305-35.305h-25.498z"
						fill="#8CD1C4"
						fillRule="evenodd"
					/>
				</mask>
				<g mask="url(#prefix__b)">
					<path
						clipRule="evenodd"
						d="M280.703 54.696c-19.498 0-35.305 15.807-35.305 35.305v74.533h-74.535c-19.498 0-35.304 15.806-35.304 35.304v25.498c0 19.499 15.806 35.305 35.304 35.305h74.535v74.533c0 19.498 15.807 35.304 35.305 35.304h25.498c19.499 0 35.305-15.806 35.305-35.304v-74.533h74.53c19.498 0 35.305-15.806 35.305-35.305v-25.498c0-19.498-15.807-35.304-35.305-35.304h-74.53V90.001c0-19.498-15.806-35.305-35.305-35.305h-25.498z"
						fill="#8CD1C4"
						fillRule="evenodd"
					/>
					<path
						d="M263.052 72.35c-4.903 4.903-16.672 5.884-17.652 18.633l-5.884-38.247 39.227-1.962c-.654 3.27 0 7.061-3.923 7.846-4.903.98-5.488 7.45-11.768 13.73z"
						fill="#fff"
					/>
					<path
						d="M155.178 220.435c-4.903 13.729-2.942 22.556-23.536 20.594v-20.594l-3.923-56.88c20.921-5.557 67.667-15.887 87.281-12.749 24.517 3.923 9.807 7.845 11.768 24.517 1.962 16.672-32.362 0-38.246 18.633-7.769 24.601-28.44 12.749-33.344 26.479z"
						fill="#FFD99C"
					/>
					<path
						d="M286.638 112.557c-1.569-10.199 35.305-17.979 53.938-20.594v52.957c-1.634-2.942-8.041-9.807-20.594-13.73-15.691-4.903-31.382-5.884-33.344-18.633z"
						fill="#7EC4B7"
					/>
					<path
						d="M267.958 359.69c-8.826.981-12.749-25.498-23.536-25.498l1.961 33.344 47.073 10.787s7.192-13.402 2.942-14.71c-4.249-1.308-21.379-4.707-28.44-3.923z"
						fill="#FFD99C"
					/>
				</g>
				<rect fill="#fff" height={76.494} rx={7.846} width={15.691} x={298.371} y={88.041} />
				<rect
					fill="#fff"
					height={31.382}
					rx={7.846}
					transform="rotate(90 223.84 211.608)"
					width={15.691}
					x={223.84}
					y={211.608}
				/>
				<rect
					fill="#fff"
					height={31.382}
					rx={2.942}
					transform="rotate(90 180.691 178.264)"
					width={5.884}
					x={180.691}
					y={178.264}
				/>
				<rect fill="#fff" height={39.228} rx={3.923} width={7.846} x={316.023} y={97.849} />
				<rect fill="#fff" height={39.228} rx={1.961} width={3.923} x={265.023} y={150.806} />
				<rect fill="#44877A" height={56.88} rx={1.961} width={3.923} x={316.02} y={258.682} />
				<rect fill="#44877A" height={19.614} rx={1.961} width={3.923} x={296.402} y={301.831} />
				<rect fill="#44877A" height={19.614} rx={1.961} width={3.923} x={292.484} y={219.454} />
				<rect
					fill="#44877A"
					height={27.459}
					rx={1.961}
					transform="rotate(90 372.902 227.299)"
					width={3.923}
					x={372.902}
					y={227.299}
				/>
				<rect
					fill="#44877A"
					height={27.459}
					rx={1.961}
					transform="rotate(90 416.051 176.304)"
					width={3.923}
					x={416.051}
					y={176.304}
				/>
				<rect
					fill="#44877A"
					height={13.73}
					rx={1.961}
					transform="rotate(90 416.051 184.147)"
					width={3.923}
					x={416.051}
					y={184.147}
				/>
				<path
					d="M81.707 155.203c1.774-3.792 3.907-13.051 17.975-17.462 14.161-4.439 25.126-.403 28.601 1.448"
					stroke="#004EF8"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={3.923}
				/>
				<circle cx={370.936} cy={80.193} fill="#1066FF" r={11.768} />
				<circle cx={396.435} cy={54.696} r={6.865} stroke="#EECFF8" strokeWidth={1.961} />
				<rect fill="#FFCCE9" height={37.266} rx={1.961} width={3.923} x={389.57} y={107.653} />
				<rect
					fill="#FFCCE9"
					height={37.266}
					rx={1.961}
					transform="rotate(90 410.156 124.324)"
					width={3.923}
					x={410.156}
					y={124.324}
				/>
				<rect fill="#FA404B" height={37.266} rx={1.961} width={3.923} x={160.09} y={370.479} />
				<rect
					fill="#FA404B"
					height={37.266}
					rx={1.961}
					transform="rotate(90 180.676 387.149)"
					width={3.923}
					x={180.676}
					y={387.149}
				/>
				<path
					d="M25.71 227.199l100.258 101.676M319.941 458.74l-23.536 21.575M337.59 458.74l-23.537 21.575"
					stroke="#27277A"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={3.923}
				/>
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path d="M0 0h496v496H0z" fill="#fff" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default PlusIcon;
