import React from 'react';

function TwitterIcon({ fill = '#fff', ...props }) {
	return (
		<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 002.049-2.578 9.3 9.3 0 01-2.959 1.13 4.66 4.66 0 00-7.938 4.25A13.23 13.23 0 012.52 3.604a4.66 4.66 0 001.442 6.22 4.647 4.647 0 01-2.11-.583v.06a4.66 4.66 0 003.737 4.568 4.692 4.692 0 01-2.105.08 4.661 4.661 0 004.353 3.234 9.348 9.348 0 01-5.787 1.995 9.5 9.5 0 01-1.111-.065 13.175 13.175 0 007.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 002.323-2.41l.002-.003z"
				fill={fill}
			/>
		</svg>
	);
}

export default TwitterIcon;
