import React from 'react';
import { ArrowIcon } from '@assets/icons';
import clsx from 'clsx';

interface TableExpandArrowInterface {
	row: any;
	visible?: boolean;
	className?: string;
}

const TableExpandArrow = ({ row, visible = true, className }: TableExpandArrowInterface) => {
	if (!visible) return <></>;
	return (
		<div
			className={clsx('flex justify-end mr-4', className)}
			{...row.getToggleRowExpandedProps({
				style: {
					paddingLeft: `${row.depth * 2}rem`,
				},
			})}
		>
			<ArrowIcon className={`transform ${row.isExpanded ? '-rotate-90' : '-rotate-180'}`} height={18} width={18} />
		</div>
	);
};

export default TableExpandArrow;
