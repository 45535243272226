import ReactPaginate from 'react-paginate';
import { ArrowIcon } from '@assets/icons';
import React from 'react';
import styles from './Pagination.module.scss';

// interface PaginationInterface {
// 	canPreviousPage: boolean;
// 	canNextPage: boolean;
// }

type pageType = 'next' | 'previous';

const pageByType = {
	next: 1,
	previous: -1,
};

const Pagination = ({ pagination }: any) => {
	const { pageNumber, setPageNumber, pagesLength } = pagination;

	const setPageHandler = (value: number, type?: pageType) => {
		const page = pageNumber > 0 && pageNumber <= pagesLength && type ? pageNumber + pageByType[type] : value + 1;
		setPageNumber(page);
	};

	return (
		<ReactPaginate
			activeClassName={styles['pagination--active']}
			breakClassName="break-me"
			breakLabel="..."
			containerClassName={styles.pagination}
			disabledClassName="opacity-0"
			forcePage={pageNumber ? pageNumber - 1 : 1}
			initialPage={pageNumber - 1}
			marginPagesDisplayed={2}
			// nextClassName={!canNextPage ? styles.disabledLabel : styles.activeLabel}
			nextLabel={<ArrowIcon className="transform rotate-180" height={13} width={13} />}
			pageCount={pagesLength || 1}
			pageRangeDisplayed={2}
			// previousClassName={!canPreviousPage ? styles.disabledLabel : styles.activeLabel}
			previousLabel={<ArrowIcon height={13} width={13} />}
			onPageChange={({ selected }) => setPageHandler(selected)}
		/>
	);
};
export default Pagination;
