import React from 'react';
import clsx from 'clsx';

interface PhotoWrapperInterface {
	children: React.ReactNode;
	className: string;
	customColor?: string;
}

const PhotoWrapper = ({ children, className, customColor, ...props }: PhotoWrapperInterface) => {
	return (
		<div
			className={clsx('rounded-full', className)}
			style={{ '--customColor': customColor } as React.CSSProperties}
			{...props}
		>
			<div className="overflow-hidden rounded-full">{children}</div>
		</div>
	);
};
export default PhotoWrapper;
